<template>
  <v-content>
    <v-layout py-4 style="background-color: #3e7662">
      <v-flex ml-6 class="font-weight-bold">
        <span style="color: white; font-size: 24px">{{ nameTable }}</span>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex>
        <v-divider
          style="background-color: #544a44; border-width: 3px"
        ></v-divider>
      </v-flex>
    </v-layout>

    <!-- Table -->
    <v-layout>
      <v-flex class="table">
        <v-data-table
          :headers="headers"
          :items="items"
          hide-default-footer
          :items-per-page="9999999999"
        >
          <template v-if="onEdit" v-slot:item="{ item }">
            <!-- Table RF12 -->
            <tr v-if="item.edit === 'RF12'">
              <td class="text-center">{{ item.number }}</td>
              <td class="text-left">{{ item.nameResearch }}</td>
              <td class="text-center">{{ item.groupID }}</td>
              <td class="text-center">{{ item.version }}</td>
              <td class="text-center">{{ item.status }}</td>
              <td class="text-center">{{ item.no }}</td>
              <td class="text-center">{{ item.created_at }}</td>
              <td class="text-center">
                <v-icon
                  color="#3E7662"
                  :disabled="
                    item.isOld === 1 ||
                    item.status === 'อนุมัติ' ||
                    item.status === 'อยู่ระหว่างการพิจารณา' ||
                    item.status === 'ผ่านการพิจารณา' ||
                    item.status === 'ปรับปรุงแก้ไขเพื่อพิจารณาใหม่'
                  "
                  style="color: #3e7662; cursor: pointer"
                  @click="
                    $router.push({
                      name: 'FormRF12',
                      query: {
                        rfID: item.id,
                        researchID: item.item.research[0].id,
                        status: item.item.research[0].status,
                        researchCode: item.item.research[0].researchCode,
                        thName: item.item.research[0].user.thName,
                        mobilePhone: item.item.research[0].user.mobilePhone,
                        affiliation: item.item.research[0].user.affiliation,
                        nameResearch: item.item.research[0].thResearchName,
                      },
                    })
                  "
                >
                  edit
                </v-icon>
              </td>
            </tr>

            <!-- Table RF04_1 -->
            <tr v-else-if="item.edit === 'RF04_1'">
              <td class="text-center">{{ item.number }}</td>
              <td class="text-left">{{ item.nameResearch }}</td>
              <td class="text-center">{{ item.groupID }}</td>
              <td class="text-center">{{ item.version }}</td>
              <td class="text-center">{{ item.status }}</td>
              <td class="text-center">{{ item.no }}</td>
              <td class="text-center">{{ item.created_at }}</td>
              <td class="text-center">
                <v-icon
                  color="#3E7662"
                  :disabled="
                    item.isOld === 1 ||
                    item.status === 'อนุมัติ' ||
                    item.status === 'อยู่ระหว่างการพิจารณา' ||
                    item.status === 'ผ่านการพิจารณา'
                  "
                  style="color: #3e7662; cursor: pointer"
                  @click="
                    $router.push({
                      name: 'FormRF04_1',
                      query: {
                        rfID: item.id,
                        researchID: item.item.research[0].id,
                        status: item.item.research[0].status,
                        researchCode: item.item.research[0].researchCode,
                        thName: item.item.research[0].user.thName,
                        mobilePhone: item.item.research[0].user.mobilePhone,
                        affiliation: item.item.research[0].user.affiliation,
                        nameResearch: item.item.research[0].thResearchName,
                      },
                    })
                  "
                >
                  edit
                </v-icon>
              </td>
            </tr>

            <!-- Table RF04_2 -->
            <tr v-else-if="item.edit === 'RF04_2'">
              <td class="text-center">{{ item.number }}</td>
              <td class="text-left">{{ item.nameResearch }}</td>
              <td class="text-center">{{ item.groupID }}</td>
              <td class="text-center">{{ item.version }}</td>
              <td class="text-center">{{ item.status }}</td>
              <td class="text-center">{{ item.no }}</td>
              <td class="text-center">{{ item.created_at }}</td>
              <td class="text-center">
                <v-icon
                  color="#3E7662"
                  :disabled="
                    item.isOld === 1 ||
                    item.status === 'อนุมัติ' ||
                    item.status === 'อยู่ระหว่างการพิจารณา' ||
                    item.status === 'ผ่านการพิจารณา'
                  "
                  style="color: #3e7662; cursor: pointer"
                  @click="
                    $router.push({
                      name: 'FormRF04_2',
                      query: {
                        rfID: item.id,
                        researchID: item.item.research[0].id,
                        status: item.item.research[0].status,
                        researchCode: item.item.research[0].researchCode,
                        thName: item.item.research[0].user.thName,
                        mobilePhone: item.item.research[0].user.mobilePhone,
                        affiliation: item.item.research[0].user.affiliation,
                        nameResearch: item.item.research[0].thResearchName,
                      },
                    })
                  "
                >
                  edit
                </v-icon>
              </td>
            </tr>

            <!-- Table RF05 -->
            <tr v-else-if="item.edit === 'RF05'">
              <td class="text-center">{{ item.number }}</td>
              <td class="text-left">{{ item.nameResearch }}</td>
              <td class="text-center">{{ item.groupID }}</td>
              <td class="text-center">{{ item.version }}</td>
              <td class="text-center">{{ item.status }}</td>
              <td class="text-center">{{ item.no }}</td>
              <td class="text-center">{{ item.created_at }}</td>
              <td class="text-center">
                <v-icon
                  color="#3E7662"
                  :disabled="
                    item.isOld === 1 ||
                    item.status === 'อนุมัติ' ||
                    item.status === 'อยู่ระหว่างการพิจารณา' ||
                    item.status === 'ผ่านการพิจารณา'
                  "
                  style="color: #3e7662; cursor: pointer"
                  @click="
                    $router.push({
                      name: 'FormRF05',
                      query: {
                        rfID: item.id,
                        researchID: item.item.research[0].id,
                        status: item.item.research[0].status,
                        researchCode: item.item.research[0].researchCode,
                        thName: item.item.research[0].user.thName,
                        mobilePhone: item.item.research[0].user.mobilePhone,
                        affiliation: item.item.research[0].user.affiliation,
                        nameResearch: item.item.research[0].thResearchName,
                      },
                    })
                  "
                >
                  edit
                </v-icon>
              </td>
            </tr>

            <!-- Table RF03 -->
            <tr v-else-if="item.edit === 'RF03'">
              <td class="text-center">{{ item.number }}</td>
              <td class="text-left">{{ item.nameResearch }}</td>
              <td class="text-center">{{ item.groupID }}</td>
              <td class="text-center">{{ item.version }}</td>
              <td class="text-center">{{ item.status }}</td>
              <td class="text-center">{{ item.no }}</td>
              <td class="text-center">{{ item.created_at }}</td>
              <td class="text-center">
                <v-icon
                  color="#3E7662"
                  :disabled="
                    item.isOld === 1 ||
                    item.status === 'อนุมัติ' ||
                    item.status === 'อยู่ระหว่างการพิจารณา' ||
                    item.status === 'ผ่านการพิจารณา'
                  "
                  style="color: #3e7662; cursor: pointer"
                  @click="
                    $router.push({
                      name: 'FormRF03',
                      query: {
                        rfID: item.id,
                        researchID: item.item.research[0].id,
                        status: item.item.research[0].status,
                        researchCode: item.item.research[0].researchCode,
                        thName: item.item.research[0].user.thName,
                        mobilePhone: item.item.research[0].user.mobilePhone,
                        affiliation: item.item.research[0].user.affiliation,
                        nameResearch: item.item.research[0].thResearchName,
                      },
                    })
                  "
                >
                  edit
                </v-icon>
              </td>
            </tr>

            <!-- Table RF06 -->
            <tr v-else-if="item.edit === 'RF06'">
              <td class="text-center">{{ item.number }}</td>
              <td class="text-left">{{ item.nameResearch }}</td>
              <td class="text-center">{{ item.groupID }}</td>
              <td class="text-center">{{ item.version }}</td>
              <td class="text-center">{{ item.status }}</td>
              <td class="text-center">{{ item.no }}</td>
              <td class="text-center">{{ item.created_at }}</td>
              <td class="text-center">
                <v-icon
                  color="#3E7662"
                  :disabled="
                    item.isOld === 1 ||
                    item.status === 'อนุมัติ' ||
                    item.status === 'อยู่ระหว่างการพิจารณา' ||
                    item.status === 'ผ่านการพิจารณา'
                  "
                  style="color: #3e7662; cursor: pointer"
                  @click="
                    $router.push({
                      name: 'FormRF06',
                      query: {
                        rfID: item.id,
                        researchID: item.item.research[0].id,
                        status: item.item.research[0].status,
                        researchCode: item.item.research[0].researchCode,
                        thName: item.item.research[0].user.thName,
                        budgetResource: item.item.research[0].budgetResource,
                        nameResearch: item.item.research[0].thResearchName,
                        mobilePhone: item.item.research[0].user.mobilePhone,
                        email: item.item.research[0].user.email,
                      },
                    })
                  "
                >
                  edit
                </v-icon>
              </td>
            </tr>

            <!-- Table RF07 -->
            <tr v-else-if="item.edit === 'RF07'">
              <td class="text-center">{{ item.number }}</td>
              <td class="text-left">{{ item.nameResearch }}</td>
              <td class="text-center">{{ item.groupID }}</td>
              <td class="text-center">{{ item.version }}</td>
              <td class="text-center">{{ item.status }}</td>
              <td class="text-center">{{ item.no }}</td>
              <td class="text-center">{{ item.created_at }}</td>
              <td class="text-center">
                <v-icon
                  color="#3E7662"
                  :disabled="
                    item.isOld === 1 ||
                    item.status === 'อนุมัติ' ||
                    item.status === 'อยู่ระหว่างการพิจารณา' ||
                    item.status === 'ผ่านการพิจารณา'
                  "
                  style="color: #3e7662; cursor: pointer"
                  @click="
                    $router.push({
                      name: 'FormRF07',
                      query: {
                        rfID: item.id,
                        researchID: item.item.research[0].id,
                        status: item.item.research[0].status,
                        researchCode: item.item.research[0].researchCode,
                        thName: item.item.research[0].user.thName,
                        budgetResource: item.item.research[0].budgetResource,
                        nameResearch: item.item.research[0].thResearchName,
                        mobilePhone: item.item.research[0].user.mobilePhone,
                        email: item.item.research[0].user.email,
                      },
                    })
                  "
                >
                  edit
                </v-icon>
              </td>
              <!--                            <td><pre class="success">{{item.item}}</pre></td>-->
            </tr>
            <!-- Table RF07 -->
            <tr v-else-if="item.edit === 'Corres'">
              <td class="text-center">{{ item.number }}</td>
              <td class="text-left">{{ item.corresName }}</td>
              <td class="text-center">{{ item.groupID }}</td>
              <td class="text-center">{{ item.status }}</td>
              <td class="text-center">{{ item.no }}</td>
              <td class="text-center">{{ item.created_at }}</td>
              <td class="text-center">
                <!--<a :href="item.filePath" target="_blank" style="text-decoration: none;">-->
                <!--<v-icon color="#3E7662"-->
                <!--style="color: #3E7662;cursor: pointer"-->
                <!--&gt;-->
                <!--visibility-->
                <!--</v-icon>-->
                <v-icon
                  color="#3E7662"
                  style="color: #3e7662; cursor: pointer"
                  @click="viewCorresFile(item.item)"
                >
                  visibility
                </v-icon>
                <!--</a>-->
              </td>
              <!--                            <td><pre class="success">{{item.item}}</pre></td>-->
            </tr>
            <!-- Table RF07 -->
            <tr v-else-if="item.edit === 'Susar'">
              <td class="text-center">{{ item.number }}</td>
              <td class="text-left">{{ item.nameResearch }}</td>
              <td class="text-center">{{ item.groupID }}</td>
              <td class="text-center">{{ item.version }}</td>
              <td class="text-center">{{ item.status }}</td>
              <td class="text-center">{{ item.no }}</td>
              <td class="text-center">{{ item.created_at }}</td>
                <!-- <td>{{item.item}}</td> -->
              <td class="text-center">
                <v-icon
                  color="#3E7662"
                  :disabled="
                    item.isOld === 1 ||
                    item.status === 'อนุมัติ' ||
                    item.status === 'อยู่ระหว่างการพิจารณา' ||
                    item.status === 'ผ่านการพิจารณา'
                  "
                  style="color: #3e7662; cursor: pointer"
                  @click="
                    $router.push({
                      name: 'FormSusar',
                      query: {
                        rfID: item.id,
                        researchID: item.item.researchID,
                        status: item.item.status,
                        researchCode: item.item.researchCode,
                        firstName: item.item.firstName,
                        lastName: item.item.lastName,
                        nameResearch: item.item.researchName,
                        startReport: item.item.startReport,
                        hasUnexpectedEvents: item.item.hasUnexpectedEvents,
                        hasDDLetter: item.item.hasDDLetter,
                        hasSafetyReport: item.item.hasSafetyReport,
                        hasMoreReport: item.item.hasMoreReport,
                        thName: item.item.signature
                      },
                    })
                  "
                >
                  edit
                </v-icon>
              </td>
              <!--                            <td><pre class="success">{{item.item}}</pre></td>-->
            </tr>
          </template>

          <template
            v-else-if="nameTable === 'งานวิจัยทั้งหมด'"
            v-slot:item="{ item }"
          >
            <tr>
              <td class="text-center">{{ item.number }}</td>
              <td class="text-start" style="word-break: break-all">
                {{ item.researchName }}
              </td>
              <td class="text-center">{{ item.status }}</td>
              <td class="text-center">{{ item.version }}</td>
            </tr>
          </template>

          <template
            v-else-if="nameTable === 'ผู้เข้าร่วมวิจัย/ที่ปรึกษา'"
            v-slot:item="{ item }"
          >
            <tr>
              <td class="text-center">{{ item.number }}</td>
              <td class="text-center">{{ item.name }}</td>
              <td class="text-left" style="word-break: break-all">
                {{ item.research }}
              </td>
              <td class="text-center">{{ item.role }}</td>
              <td class="text-center">{{ item.status }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>

    <v-layout>
      <v-flex>
        <v-divider
          style="background-color: #544a44; border-width: 3px"
        ></v-divider>
      </v-flex>
    </v-layout>
  </v-content>
</template>

<script>
export default {
  name: "FormTable",
  props: {
    headers: Array,
    items: Array,
    nameTable: String,
    itemsPerPage: Number,
    onEdit: Boolean,
    viewCorresFile: Function,
  },
};
</script>

<style lang="scss">
table th + th {
  border-left: 1px solid #dddddd;
}
table td + td {
  border-left: 1px solid #dddddd;
}
.table {
  th.text-center.sortable {
    font-weight: bold;
    font-size: 16px;
  }
}
.table {
  th.text-left.sortable {
    font-weight: bold;
    font-size: 16px;
  }
}
table thead tr th span {
  text-align: center !important;
}
tbody tr td {
  color: #544a44;
}
thead.v-data-table-header tr th {
  span {
    color: #544a44;
  }
}
</style>
