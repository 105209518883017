<template>
  <v-content style="background-color: #EFEAE7">
    <v-layout style="text-align: center;">
      <v-flex pt-8>
        <span style="font-weight: bold;font-size: 36px; color: #3E7662">สร้างงานวิจัย</span>
      </v-flex>
    </v-layout>

    <v-layout>
      <v-flex style="background-color: #EFEAE7;padding: 2% 5% 2% 5%">
        <v-card>
          <v-layout pa-8 justify-center style="background-color: #3E7662;">
            <v-flex style="text-align: left;">
              <span style="font-weight: bold;font-size: 28px;color: white;">แบบโครงร่างการวิจัย</span>
            </v-flex>
            <v-flex style="text-align: right;">
              <span style="font-size: 14px;color: white;">Version……….Date…………………….</span><br />
              <span style="font-size: 14px;color: white;">RF 08_2_2563</span>
            </v-flex>
          </v-layout>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-layout>
              <v-flex px-12 py-6>
                <!-- choice 1 -->
                <v-layout row align-center my-3 pl-4>
                  <v-flex style="text-align: left;" shrink>
                    <span style="font-weight: bold;font-size: 16px;">ประเภทการส่ง</span>
                  </v-flex>
                  <v-flex pl-4>
                    <v-radio-group row v-model="submissionType">
                      <v-radio color="#3E7662" label="Initial submission" value="0" :disabled="!isNew"></v-radio>
                      <v-radio color="#3E7662" label="Re submission (Manual)" value="2" :disabled="!isNew"></v-radio>
                      <v-radio color="#3E7662" label="Re submission" value="1" :disabled="isNew"></v-radio>
                    </v-radio-group>
                  </v-flex>
                  <v-flex v-if="submissionType == 2">
                    <span>รหัสโครงการ*</span>
                    <TextfieldForm :model="form.researchs.researchCode"
                      @update-data="updateDataResearchs('researchCode', $event)"></TextfieldForm>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex style="text-align: left;">
                    <span style="font-weight: bold;font-size: 16px;">1. ชื่อโครงการ</span>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex pt-2 style="text-align: left;">
                    <span class="pl-3" style="font-weight: bold;font-size: 16px;color: #CC3D45;">*ภาษาไทย</span>
                  </v-flex>
                </v-layout>
                <TextfieldForm :model="form.researchs.thResearchName"
                  @update-data="updateDataResearchs('thResearchName', $event)"></TextfieldForm>

                <v-layout>
                  <v-flex style="text-align: left;">
                    <span class="pl-3" style="font-weight: bold;font-size: 16px;color: #CC3D45;">*ภาษาอังกฤษ</span>
                  </v-flex>
                </v-layout>
                <TextfieldForm :model="form.researchs.enResearchName"
                  @update-data="updateDataResearchs('enResearchName', $event)"></TextfieldForm>

                <!-- choice 1.1 -->
                <v-layout class="mt-6">
                  <v-flex xs2 pt-2 style="text-align: left;">
                    <span style="font-weight: bold;font-size: 16px;">1.1 Protocol No.(ถ้ามี)</span>
                  </v-flex>
                  <v-flex xs4 class="text-input">
                    <TextfieldForm :model="form.researchs.protocolNumber" @update-data="
                      updateDataResearchs('protocolNumber', $event)
                      "></TextfieldForm>
                  </v-flex>
                </v-layout>
                <v-layout class="mt-6">
                  <v-flex xs3 pt-2 style="text-align: left;">
                    <span style="font-weight: bold;font-size: 16px;">1.2 โครงร่างวิจัย CREC / YMID (ถ้ามี)</span>
                  </v-flex>

                  <v-select clearable label="เลือกประเภทโครงร่าง CREC / YMID" background-color="#3E7662" solo flat dense
                    dark :items="ymidType" :menu-props="{ bottom: true, offsetY: true }" v-model="form.researchs.ymidtype"
                    item-text="name" item-value="name">
                  </v-select>

                  <v-flex xs4 pt-2 pl-4 style="text-align: left;">
                    <span style="font-weight: bold;font-size: 16px;">1.3 รหัสโครงร่างวิจัย CREC / YMID (ถ้ามี)</span>
                  </v-flex>
                  <v-flex xs4 class="text-input">
                    <TextfieldForm :model="form.researchs.ymidname"
                      @update-data="updateDataResearchs('ymidname', $event)"></TextfieldForm>
                  </v-flex>
                </v-layout>
                <v-layout class="mt-6">
                  <v-flex xs4 pt-2 style="text-align: left;">
                    <span style="font-weight: bold;font-size: 16px;">1.4 เอกสารรับรองโครงร่างจาก CREC / YMID</span>
                  </v-flex>
                  <v-flex xs4 class="text-input">
                    <v-file-input accept=".pdf" prepend-icon="" id="crecFile" :model="form.researchs.crecFile" outlined
                      dense background-color="#FBF6F3" color="#3E7662" item-color="#3E7662"
                      @change="uploadFileChange('crecFile', $event)" hide-details></v-file-input>
                  </v-flex>
                  <v-flex shrink pl-4 style="text-align: left;justify-items: end;">
                    <v-btn color="#3E7662" dark @click="uploadFile('crecFile')">
                      <v-icon class="pr-3">cloud_download</v-icon>
                      <span>Upload</span>
                    </v-btn>
                  </v-flex>
                </v-layout>

                <v-layout class="mt-6">
                  <v-flex xs2 pt-2 style="text-align: left;">
                    <span style="font-weight: bold;font-size: 16px;">2. *สาขาที่ทำการวิจัย</span>
                  </v-flex>
                  <TextfieldForm :model="form.researchs.field" @update-data="updateDataResearchs('field', $event)">
                  </TextfieldForm>
                </v-layout>

                <!-- choice 3 -->
                <v-layout mb-6 mt-6>
                  <v-flex shrink pr-4 style="text-align: left;" class="d-flex align-center">
                    <span style="font-weight: bold;font-size: 16px;">3. ผู้วิจัยและที่ปรึกษา</span>
                  </v-flex>
                  <v-spacer></v-spacer>
                  <v-flex shrink style="text-align: left;">
                    <v-btn dark style="width: 80px;background-color: #3E7662" @click="add(1)">
                      <v-icon>add</v-icon>
                      <span class="font-weight-bold">เพิ่ม</span>
                    </v-btn>
                  </v-flex>
                </v-layout>
                <v-layout mb-12>
                  <v-flex>
                    <div class="mb-5">
                      <v-layout>
                        <v-flex xs3 class="field-select">
                          <v-text-field hide-details :menu-props="{ bottom: true, offsetY: true }"
                            background-color="#3E7662" solo flat dense readonly dark v-model="placeHolder"></v-text-field>
                        </v-flex>
                        <v-flex xs1></v-flex>
                        <v-flex shrink ml-6 mr-4 pt-2 class="text-end font-weight-bold">
                          <span>*ชื่อ-นามสกุล</span>
                        </v-flex>
                        <v-flex xs3 class="field-select">
                          <text-autocomplete id="province" placeholder="เลือก ชื่อผู้วิจัยหลัก" :items="user_list"
                            item-text="user_name" itemValue="user_id" :model="form.researchs.mainResearcherID"
                            @update-data="
                              updateDataResearchs('mainResearcherID', $event)
                              " />
                        </v-flex>
                        <v-spacer></v-spacer>
                        <v-flex ml-6 xs1 class="justify-end"> </v-flex>
                      </v-layout>
                      <v-layout>
                        <v-flex xs4></v-flex>
                        <v-flex xs2>
                          <v-file-input hidden accept=".pdf" prepend-inner-icon="attach" id="researcherProfile"
                            :model="form.researchs.researcherProfile" outlined dense label="ประวัติผู้วิจัย"
                            background-color="#FBF6F3" color="#3E7662" item-color="#3E7662"
                            @change="uploadFileChange('researcherProfile', $event)" hide-details></v-file-input>
                        </v-flex>
                        <v-flex xs2 ml-4>
                          <v-btn color="#3E7662" :disabled="researcherProfilePath == '' || researcherProfilePath == null"
                            :dark="!(researcherProfilePath == '' || researcherProfilePath == null)"
                            @click="openPDF('researcherProfilePath')">
                            <v-icon class="pr-3">cloud_download</v-icon>
                            <span>Download</span>
                          </v-btn>
                        </v-flex>
                        <v-flex xs3 ml-4><span style="color: red">ชื่อไฟล์ต้องเป็นภาษาอังกฤษเท่านั้น (a-z,0-9)
                            ห้ามมีตัวอักษรอื่น </span></v-flex>
                      </v-layout>
                    </div>
                    <div class="mb-5" v-for="(input, k) in researchers" :key="k">
                      <v-layout align-content-center>
                        <v-flex xs3 class="field-select">
                          <v-select clearable label="ผู้ร่วมวิจัย / ที่ปรึกษา / ผู้ประสานงาน"
                            :menu-props="{ bottom: true, offsetY: true }" v-model="input.position"
                            background-color="#3E7662" solo flat dense :items="researcher_type" item-text="type"
                            item-value="type" :value-comparator="findMatchingProvince"></v-select>
                        </v-flex>
                        <v-flex xs1></v-flex>
                        <v-flex shrink ml-6 mr-4 pt-2 class="text-end font-weight-bold">
                          <span>ชื่อ-นามสกุล</span>
                        </v-flex>
                        <v-flex xs3 class="field-select" v-if="input.position">
                          <text-autocomplete id="province" placeholder="เลือก ชื่อผู้ร่วมวิจัย / ที่ปรึกษา / ผู้ประสานงาน"
                            :menu-props="{ bottom: true, offsetY: true }" :items="user_list" item-text="user_name"
                            itemValue="user_id" :model="input.userID" @update-data="updateData(k, $event)" />
                        </v-flex>
                        <v-flex xs3 class="field-select" v-else>
                          <text-autocomplete id="province" placeholder="เลือก ชื่อผู้ร่วมวิจัย / ที่ปรึกษา / ผู้ประสานงาน"
                            :items="user_list" item-text="user_name" itemValue="user_id" :model="input.userID"
                            @update-data="updateData(k, $event)" disabled />
                        </v-flex>


                        <v-flex xs2 class="d-flex justify-end">
                          <v-checkbox v-model="input.canManage" color="#3E7662" label="มีสิทธิ์แก้ไข">
                          </v-checkbox>
                        </v-flex>
                        <v-spacer></v-spacer>
                        <v-flex ml-6 xs1 class="justify-end">
                          <v-btn dark style="background-color: #CC3D45; width: 80px" @click="remove(k)"
                            :disabled="researchers.length <= 1">
                            <v-icon>remove</v-icon>
                            <span class="font-weight-bold">ลบ</span>
                          </v-btn>
                        </v-flex>
                      </v-layout>
                      <v-layout>
                        <v-flex xs4></v-flex>
                        <v-flex xs2>
                          <v-file-input hidden accept=".pdf" prepend-inner-icon="attach" id="historyFile"
                            :model="input.historyFile" outlined dense label="ประวัติผู้วิจัย" background-color="#FBF6F3"
                            color="#3E7662" item-color="#3E7662" @change="
                              uploadFileChange('historyFile', $event, k)
                              " hide-details></v-file-input>
                        </v-flex>
                        <v-flex xs2 ml-4>
                          <v-btn color="#3E7662" :disabled="input.historyFilePath == '' || input.historyFilePath == null"
                            :dark="!(input.historyFilePath == '' || input.historyFilePath == null)"
                            @click="openPDF(input.historyFilePath, 1)">
                            <v-icon class="pr-3">cloud_download</v-icon>
                            <span>Download</span>
                          </v-btn>
                        </v-flex>
                        <v-flex xs3 ml-4><span style="color: red">ชื่อไฟล์ต้องเป็นภาษาอังกฤษเท่านั้น (a-z,0-9)
                            ห้ามมีตัวอักษรอื่น </span></v-flex>
                      </v-layout>
                    </div>
                  </v-flex>
                </v-layout>
                <!--                            <pre class="success">Index: {{indexArr}}</pre>-->
                <!--                            <pre class="warning">researchers: {{researchers.length}}</pre>-->

                <!-- choice 4 -->
                <v-layout mb-6>
                  <v-flex shrink style="text-align: left;">
                    <date-input-form :model="dateChange1" style="font-weight: bold"
                      label="4. ระยะเวลาที่ทำการวิจัย *เริ่ม" @update-data="updateDate('startDate', $event)"
                      :min="nowDate"></date-input-form>
                  </v-flex>
                  <v-flex shrink pl-12 style="text-align: right;">
                    <date-input-form :model="dateChange2" style="font-weight: bold" label="*สิ้นสุด"
                      @update-data="updateDate('endDate', $event)" :min="nowDate"></date-input-form>
                  </v-flex>
                </v-layout>

                <!-- choice 5 -->
                <v-layout>
                  <v-flex xs10 pr-12 pb-6>
                    <v-layout>
                      <v-flex xs4 pt-2 pr-4 style="text-align: left;">
                        <span style="font-weight: bold;font-size: 16px;">5.1. *สถานที่ทำการวิจัย</span>
                      </v-flex>
                      <v-flex class="text-input">
                        <TextareaForm :model="form.researchs.researchPlace"
                          @update-data="updateDataResearchs('researchPlace', $event)"></TextareaForm>
                      </v-flex>
                    </v-layout>
                    <v-layout>
                      <v-flex xs4 pt-2 pr-4 style="text-align: left;">
                        <span style="font-weight: bold;font-size: 16px;">5.2. *สถานที่ทำการวิจัย (ภาษาอังกฤษ)</span>
                      </v-flex>
                      <v-flex class="text-input">
                        <TextareaForm :model="form.researchs.researchPlaceEn"
                          @update-data="updateDataResearchs('researchPlaceEn', $event)"></TextareaForm>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>

                <!-- choice 6 -->
                <v-layout>
                  <v-flex>
                    <span style="font-weight: bold;font-size: 16px;">6. *ชนิดของการศึกษา</span>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex xs12>
                    <v-radio-group style="width: 100%" v-model="form.researchs.educationType"
                      @change="clearTextField('otherEducationType')">
                      <v-layout class="pl-6">
                        <v-flex xs4>
                          <v-radio color="#3E7662" label="Laboratory study" value="Laboratory study">
                          </v-radio>
                          <v-radio color="#3E7662" label="Observational study" value="Observational study">
                          </v-radio>
                          <v-radio color="#3E7662" label="Experimental study (Clinical trial)"
                            value="Experimental study (Clinical trial)">
                          </v-radio>
                          <v-radio color="#3E7662" label="Medical record review" value="Medical record review">
                          </v-radio>
                        </v-flex>
                        <v-flex xs4>
                          <v-radio color="#3E7662" label="Registration" value="Registration">
                          </v-radio>
                          <v-radio color="#3E7662" label="แบบสอบถาม (Questionnaire)" value="แบบสอบถาม (Questionnaire)">
                          </v-radio>
                          <v-radio color="#3E7662" label="Case report" value="Case report">
                          </v-radio>
                          <v-layout>
                            <v-flex xs4>
                              <v-radio color="#3E7662" label="อื่นๆ ระบุ" value="อื่นๆ ระบุ">
                              </v-radio>
                            </v-flex>
                            <v-flex shrink pl-3 mt-n1>
                              <TextfieldForm :disabled="form.researchs.educationType !== 'อื่นๆ ระบุ'"
                                :background-color="form.researchs.educationType !== 'อื่นๆ ระบุ' ? '#e1e1e1e1' : 'rgb(251, 246, 243)'"
                                :model="form.researchs.otherEducationType"
                                @update-data="updateDataResearchs('otherEducationType', $event)"></TextfieldForm>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-radio-group>
                  </v-flex>
                </v-layout>

                <!--Choice 7-->
                <v-layout>
                  <v-flex>
                    <span style="font-weight: bold;font-size: 16px;">7. *กลุ่มประชากร (เลือกได้มากกว่า 1 )
                    </span>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex xs1></v-flex>
                  <v-flex xs3>
                    <v-checkbox color="#3E7662" v-model="form.researchs.checkboxSampleGroup.group1" label="ผู้ป่วย" />
                    <v-checkbox color="#3E7662" v-model="form.researchs.checkboxSampleGroup.group2"
                      label="หญิงตั้งครรภ์" />
                    <v-checkbox color="#3E7662" v-model="form.researchs.checkboxSampleGroup.group3" label="เด็ก" />
                    <v-layout>
                      <v-flex shrink>
                        <v-checkbox color="#3E7662" v-model="form.researchs.checkboxSampleGroup.group10"
                          label="อื่นๆ ระบุ" @change="clearTextField('otherSampleGroup')" />
                      </v-flex>
                      <v-flex pl-3 mt-3>
                        <TextfieldForm :disabled="!form.researchs.checkboxSampleGroup.group10"
                          :model="form.researchs.otherSampleGroup"
                          @update-data="updateDataResearchs('otherSampleGroup', $event)"></TextfieldForm>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs3>
                    <v-checkbox color="#3E7662" v-model="form.researchs.checkboxSampleGroup.group4"
                      label="พลเรือน(สุขภาพดี)" />
                    <v-checkbox color="#3E7662" v-model="form.researchs.checkboxSampleGroup.group5" label="ทหาร" />
                    <v-checkbox color="#3E7662" v-model="form.researchs.checkboxSampleGroup.group6"
                      label="นักเรียนทหาร" />
                  </v-flex>
                  <v-flex xs3>
                    <v-checkbox color="#3E7662" v-model="form.researchs.checkboxSampleGroup.group7"
                      label="บุคลากรในสถาบัน" />
                    <v-checkbox color="#3E7662" v-model="form.researchs.checkboxSampleGroup.group8" label="ชุมชน" />
                    <v-checkbox color="#3E7662" v-model="form.researchs.checkboxSampleGroup.group9"
                      label="ผู้บกพร่องทางสติปัญญา" />
                  </v-flex>
                </v-layout>

                <!--Choice 8-->
                <v-layout>
                  <v-flex>
                    <span style="font-weight: bold;font-size: 16px;">8. แหล่งทุนและงบประมาณในการวิจัย</span>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex xs2></v-flex>
                  <v-flex d-flex justify-end align-center xs4>
                    <a style="color: #3e7662;text-decoration: underline " @click="createBudgetModal">
                      <span class="mr-2">เพิ่มแหล่งทุนวิจัย +</span>
                    </a>
                  </v-flex>
                </v-layout>
                <v-layout mx-8 mt-4 justify-space-between>
                  <v-flex xs6>
                    <v-layout>
                      <v-flex xs4 pt-2 mr-3 style="color: #3E7662;font-weight: bold">
                        <span>*กรุณาเลือกเเหล่งทุนวิจัย</span>
                      </v-flex>
                      <v-flex xs8 class="field-select">
                        <!-- Color Select Input -->
                        <v-select clearable label="แหล่งทุน ภายนอก/ภายใน" :menu-props="{ bottom: true, offsetY: true }"
                          v-model="isInside" background-color="#3E7662" solo flat dense :items="isInsideList"
                          item-text="name" item-value="id" @change="getBudgetList()"></v-select>
                        <v-select clearable label="ชื่อแหล่งทุนวิจัย" :menu-props="{ bottom: true, offsetY: true }"
                          v-model="form.researchs.budgetResourceID" :background-color="isInside == null ? '#C4C4C4' : '#3E7662'
                            " solo flat dense :items="budget_list" item-text="budget_name" item-value="budget_id"
                          :disabled="isInside == null"></v-select>
                      </v-flex>
                    </v-layout>
                  </v-flex>

                  <v-flex xs6>
                    <v-layout>
                      <v-flex ml-8 mr-3 xs2 pt-2 style="color: #3E7662;font-weight: bold">
                        <span>*ยอดรวม</span>
                      </v-flex>
                      <v-flex xs8 d-flex justify-end>
                        <TextfieldForm hide-detail type="number" :model="form.researchs.budgetAmount"
                          @update-data="updateDataResearchs('budgetAmount', $event)"></TextfieldForm>
                      </v-flex>
                      <v-flex ml-3 xs2 pt-2 style="color: #3E7662;font-weight: bold">
                        <span>บาท</span>
                      </v-flex>
                    </v-layout>
                    <v-layout>
                      <v-flex ml-8 mr-3 xs2></v-flex>
                      <v-flex d-flex ่justify-start>
                        <span style="color: #cc3d45;">*กรอกเฉพาะตัวเลขเท่านั้น</span>
                      </v-flex>
                      <v-flex xs2></v-flex>
                    </v-layout>
                  </v-flex>


                </v-layout>

                <!--Choice 9-->
                <v-layout>
                  <v-flex>
                    <span style="font-weight: bold;font-size: 16px;">9. *รายการมีส่วนได้ส่วนเสีย (การที่บุคคลมีผลประโยชน์
                      เป็นหุ้นส่วนของบริษัท
                      หรือมีส่วนได้ส่วนเสียกับบริษัทยา)</span>
                  </v-flex>
                </v-layout>
                <v-layout mt-6>
                  <v-flex xs1></v-flex>
                  <v-flex xs5>
                    <v-checkbox color="#3E7662" v-model="form.researchs.checkboxInterestedGroup.interested1
                      " @change="clearTextField('ownerAmount')" label="ผู้ถือหุ้นหรือเจ้าของกิจการ" />
                    <v-checkbox color="#3E7662" v-model="form.researchs.checkboxInterestedGroup.interested2
                      " @change="clearTextField('compensationAmount')"
                      label="ค่าตอบแทน (นอกเหนือจากที่ได้รับจากงบประมาณการวิจัย)" />
                    <v-layout>
                      <v-flex shrink class="d-flex align-center">
                        <v-checkbox color="#3E7662" v-model="form.researchs.checkboxInterestedGroup.interested3
                          " @change="clearTextField('otherAmount')" label="อื่นๆ ระบุ" />
                      </v-flex>
                      <v-flex ml-4 xs6 class="d-flex align-center">
                        <TextfieldForm hideDetail :disabled="!form.researchs.checkboxInterestedGroup.interested3"
                          :backgroundColor="!form.researchs.checkboxInterestedGroup.interested3 ? '#e1e1e1' : '#FBF6F3'"
                          :model="form.researchs.checkboxInterestedGroup.hasInterested3"
                          @update-data="($event) => { form.researchs.checkboxInterestedGroup.hasInterested3 = $event; }">
                        </TextfieldForm>
                      </v-flex>
                    </v-layout>

                    <v-checkbox color="#3E7662" v-model="form.researchs.checkboxInterestedGroup.interested4
                      " label="ไม่มี" @change="clearTextField('dontHaveAmount')" />
                  </v-flex>
                  <v-flex xs4>
                    <v-layout mt-1>
                      <v-flex mt-2 shrink>
                        <span>จำนวนเงิน</span>
                      </v-flex>
                      <v-flex px-2 xs6>
                        <TextfieldForm type="number" :disabled="!form.researchs.checkboxInterestedGroup.interested1"
                          :model="form.researchs.ownerAmount" @update-data="updateDataResearchs('ownerAmount', $event)">
                        </TextfieldForm>
                      </v-flex>
                      <v-flex mt-2 shrink>
                        <span>บาท</span>
                      </v-flex>
                    </v-layout>
                    <v-layout mt-1>
                      <v-flex mt-2 shrink>
                        <span>จำนวนเงิน</span>
                      </v-flex>
                      <v-flex px-2 xs6>
                        <TextfieldForm type="number" :disabled="!form.researchs.checkboxInterestedGroup.interested2"
                          :model="form.researchs.compensationAmount"
                          @update-data="updateDataResearchs('compensationAmount', $event)"></TextfieldForm>
                      </v-flex>
                      <v-flex mt-2 shrink>
                        <span>บาท</span>
                      </v-flex>
                    </v-layout>
                    <v-layout mt-1>
                      <v-flex mt-2 shrink>
                        <span>จำนวนเงิน</span>
                      </v-flex>
                      <v-flex px-2 xs6>
                        <TextfieldForm type="number" :disabled="!form.researchs.checkboxInterestedGroup.interested3"
                          :model="form.researchs.otherAmount" @update-data="updateDataResearchs('otherAmount', $event)">
                        </TextfieldForm>
                      </v-flex>
                      <v-flex mt-2 shrink>
                        <span>บาท</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex xs6></v-flex>
                  <v-flex xs4>
                    <v-layout>
                      <v-flex mt-2 shrink>
                        <span>รวมเป็นเงิน</span>
                      </v-flex>
                      <v-flex xs6 px-2>
                        <TextfieldForm readonly :model="totalAmount"></TextfieldForm>
                      </v-flex>
                      <v-flex mt-2 shrink>
                        <span>บาท</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>

                <!--File Upload-->
                <v-layout mt-12>
                  <v-flex shrink>
                    <span style="font-weight: bold;font-size: 16px;">10. เอกสารที่ต้องส่ง</span>
                  </v-flex>
                  <v-flex pl-6>
                    <span style="font-weight: bold;font-size: 12px;color: red">*หมายเหตุ: อัพโหลดได้เฉพาะไฟล์นามสกุล PDF
                      เท่านั้น และ ชื่อไฟล์ต้องเป็นภาษาอังกฤษเท่านั้น (a-z,0-9) ห้ามมีตัวอักษรอื่น</span>
                  </v-flex>
                </v-layout>


                <div v-if="submissionType === '0'">
                  <!--Upload1-->
                  <v-layout mt-6>
                    <v-flex xs1></v-flex>
                    <v-flex xs11>
                      <v-layout>
                        <v-flex>
                          <span
                            style="font-weight: bold;font-size: 19px;color: #CC3D45;">หนังสือรับรองจากผู้บังคับบัญชาระดับ
                            ผอ.กอง หรือ
                            หัวหน้าภาควิชา*</span>
                        </v-flex>
                      </v-layout>
                      <v-layout mt-4>
                        <v-flex xs7>
                          <v-layout>
                            <v-flex shrink pt-2 pr-4 style="text-align: left;">
                              <span style="font-weight: bold;font-size: 16px;">ไฟล์</span>
                            </v-flex>
                            <v-flex>
                              <v-file-input accept=".pdf" prepend-icon="" id="guarantyFile"
                                :model="form.researchs.guarantyFile" outlined dense background-color="#FBF6F3"
                                color="#3E7662" item-color="#3E7662" @change="
                                  uploadFileChange('guarantyFile', $event)
                                  " hide-details></v-file-input>
                            </v-flex>
                            <v-flex shrink pl-4 style="text-align: left">
                              <v-btn color="#3E7662" dark @click="uploadFile('guarantyFile')">
                                <v-icon class="pr-3">cloud_download</v-icon>
                                <span>Upload</span>
                              </v-btn>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs1></v-flex>
                        <v-flex xs4>
                          <v-layout>
                            <v-flex shrink pt-2>
                              <span class="font-weight-bold">*เลขหนังสือ</span>
                            </v-flex>
                            <v-flex pl-2 xs6>
                              <TextfieldForm :hide-detail="true" :model="form.researchs.guarantyNumber" @update-data="
                                updateDataResearchs('guarantyNumber', $event)
                                "></TextfieldForm>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                      <v-layout mt-1 v-if="researchID !== 0">
                        <v-flex style="font-size: 12px;color: #CC3D45" ml-10 class="text-start">
                          <span>ชื่อไฟล์ที่อัพโหลด : </span>
                          <span class="link__pdf" @click="openPDF('guarantyFilePath')">{{ namePDF1 }}</span>
                        </v-flex>
                      </v-layout>
                      <v-layout v-if="verifyForm.noteOne">
                        <v-flex xs7 pa-2 style="border: #CC3D45 solid 1px">
                          <span>รายการแก้ไขเพิ่มเติม หนังสือรับรองจากผู้บังคับบัญชาระดับ ผอ.กอง หรือ
                            หัวหน้าภาควิชา:</span><br>
                          <span style="color: #CC3D45">{{ verifyForm.noteOne }}</span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                  <!--Upload2-->
                  <v-layout mt-6>
                    <v-flex xs1></v-flex>
                    <v-flex xs11>
                      <v-layout>
                        <v-flex>
                          <span style="font-weight: bold;font-size: 19px;color: #CC3D45;">เอกสารโครงร่างงานวิจัย (RF
                            08_2_2563)*</span>
                        </v-flex>
                      </v-layout>
                      <v-layout mt-4>
                        <v-flex xs7>
                          <v-layout>
                            <v-flex shrink pt-2 pr-4 style="text-align: left;">
                              <span style="font-weight: bold;font-size: 16px;">ไฟล์</span>
                            </v-flex>
                            <v-flex>
                              <v-file-input accept=".pdf" prepend-icon="" id="researchFile"
                                :model="form.researchs.researchFile" outlined dense background-color="#FBF6F3"
                                color="#3E7662" item-color="#3E7662" @change="
                                  uploadFileChange('researchFile', $event)
                                  " hide-details></v-file-input>
                            </v-flex>
                            <v-flex shrink pl-4 style="text-align: left">
                              <v-btn color="#3E7662" dark @click="uploadFile('researchFile')">
                                <v-icon class="pr-3">cloud_download</v-icon>
                                <span>Upload</span>
                              </v-btn>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex></v-flex>
                      </v-layout>
                      <v-layout mt-1 v-if="researchID !== 0">
                        <v-flex style="font-size: 12px;color: #CC3D45" ml-10 class="text-start">
                          <span>ชื่อไฟล์ที่อัพโหลด : </span>
                          <span class="link__pdf" @click="openPDF('researchFilePath')">{{ namePDF2 }}</span>
                        </v-flex>
                      </v-layout>
                      <v-layout v-if="verifyForm.noteTwo">
                        <v-flex xs7 pa-2 style="border: #CC3D45 solid 1px">
                          <span>รายการแก้ไขเพิ่มเติม เอกสารโครงร่างงานวิจัย (RF 08_2_2563):</span><br>
                          <span style="color: #CC3D45">{{ verifyForm.noteTwo }}</span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                  <!--Upload3-->
                  <v-layout mt-6>
                    <v-flex xs1></v-flex>
                    <v-flex xs11>
                      <v-layout>
                        <v-flex>
                          <span
                            style="font-weight: bold;font-size: 19px;color: #CC3D45;">หนังสือข้อตกลงรักษาความลับของข้อมูลฯ
                            (RO01_1_2563)*</span>
                        </v-flex>
                      </v-layout>
                      <v-layout mt-4>
                        <v-flex xs7>
                          <v-layout>
                            <v-flex shrink pt-2 pr-4 style="text-align: left;">
                              <span style="font-weight: bold;font-size: 16px;">ไฟล์</span>
                            </v-flex>
                            <v-flex>
                              <v-file-input accept=".pdf" prepend-icon="" id="ro01_1File"
                                :model="form.researchs.ro01_1File" outlined dense background-color="#FBF6F3"
                                color="#3E7662" item-color="#3E7662" @change="uploadFileChange('ro01_1File', $event)"
                                hide-details></v-file-input>
                            </v-flex>
                            <v-flex shrink pl-4 style="text-align: left">
                              <v-btn color="#3E7662" dark @click="uploadFile('ro01_1File')">
                                <v-icon class="pr-3">cloud_download</v-icon>
                                <span>Upload</span>
                              </v-btn>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                      <v-layout mt-1>
                        <v-flex style="font-size: 12px;color: #CC3D45" ml-10 class="text-start">
                          <b>หมายเหตุ: ถ้ามีมากกว่า 1 ไฟล์ ให้รวมไฟล์ </b>
                        </v-flex>
                      </v-layout>
                      <v-layout mt-1 v-if="researchID !== 0">
                        <v-flex style="font-size: 12px;color: #CC3D45" ml-10 class="text-start">
                          <span>ชื่อไฟล์ที่อัพโหลด : </span>
                          <span class="link__pdf" @click="openPDF('ro01_1FilePath')">{{ namePDF3 }}</span>
                        </v-flex>
                      </v-layout>
                      <v-layout v-if="verifyForm.noteThree">
                        <v-flex xs7 pa-2 style="border: #CC3D45 solid 1px">
                          <span>รายการแก้ไขเพิ่มเติม หนังสือข้อตกลงรักษาความลับของข้อมูลฯ (RO01_1_2563):</span><br>
                          <span style="color: #CC3D45">{{ verifyForm.noteThree }}</span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                  <!--Upload4-->
                  <v-layout mt-6>
                    <v-flex xs1></v-flex>
                    <v-flex xs11>
                      <v-layout>
                        <v-flex>
                          <span
                            style="font-weight: bold;font-size: 19px;color: #CC3D45;">หนังสือข้อตกลงในการดำเนินการวิจัย
                            (RO01_2_2563)*</span>
                        </v-flex>
                      </v-layout>
                      <v-layout mt-4>
                        <v-flex xs7>
                          <v-layout>
                            <v-flex shrink pt-2 pr-4 style="text-align: left;">
                              <span style="font-weight: bold;font-size: 16px;">ไฟล์</span>
                            </v-flex>
                            <v-flex>
                              <v-file-input accept=".pdf" prepend-icon="" id="ro01_2File"
                                :model="form.researchs.ro01_2File" outlined dense background-color="#FBF6F3"
                                color="#3E7662" item-color="#3E7662" @change="uploadFileChange('ro01_2File', $event)"
                                hide-details></v-file-input>
                            </v-flex>
                            <v-flex shrink pl-4 style="text-align: left">
                              <v-btn color="#3E7662" dark @click="uploadFile('ro01_2File')">
                                <v-icon class="pr-3">cloud_download</v-icon>
                                <span>Upload</span>
                              </v-btn>
                            </v-flex>
                          </v-layout>
                          <v-layout mt-1>
                            <v-flex style="font-size: 12px;color: #CC3D45" ml-10 class="text-start">
                              <b>หมายเหตุ: ถ้ามีมากกว่า 1 ไฟล์ ให้รวมไฟล์ </b>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs1></v-flex>
                      </v-layout>
                      <v-layout mt-1 v-if="researchID !== 0">
                        <v-flex style="font-size: 12px;color: #CC3D45" ml-10 class="text-start">
                          <span>ชื่อไฟล์ที่อัพโหลด : </span>
                          <span class="link__pdf" @click="openPDF('ro01_2FilePath')">{{ namePDF4 }}</span>
                        </v-flex>
                      </v-layout>
                      <v-layout v-if="verifyForm.noteThree">
                        <v-flex xs7 pa-2 style="border: #CC3D45 solid 1px">
                          <span>รายการแก้ไขเพิ่มเติม หนังสือข้อตกลงในการดำเนินการวิจัย (RO01_2_2563):</span><br>
                          <span style="color: #CC3D45">{{ verifyForm.noteThree }}</span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                  <!--Upload5-->
                  <v-layout mt-6>
                    <v-flex xs1></v-flex>
                    <v-flex xs11>
                      <v-layout mt-1 v-if="researchID !== 0">
                        <v-flex style="font-size: 12px;color: #CC3D45" ml-10 class="text-start">
                          <span>ชื่อไฟล์ที่อัพโหลด : </span>
                          <span class="link__pdf" @click="openPDF('crecFilePath')">{{ namePDF5 }}</span>
                          <v-icon v-if="form.researchs.crecFile" @click="cancelFile('crecFile', 'namePDF5')"
                            style="cursor: pointer">mdi-close</v-icon>
                        </v-flex>
                      </v-layout>
                      <v-layout v-if="verifyForm.noteFour">
                        <v-flex xs7 pa-2 style="border: #CC3D45 solid 1px">
                          <span>รายการแก้ไขเพิ่มเติม เอกสารรับรองโครงร่างการวิจัย จาก CREC/YMID:</span><br>
                          <span style="color: #CC3D45">{{ verifyForm.noteFour }}</span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                  <!--แบบรายงานการส่งโครงร่างวิจัยเพื่อพิจารณา(RF 01_2563)-->
                  <v-layout mt-6>
                    <v-flex xs1></v-flex>
                    <v-flex xs11>
                      <v-layout>
                        <v-flex>
                          <span style="font-weight: bold;font-size: 19px;">แบบรายงานการส่งโครงร่างวิจัยเพื่อพิจารณา(RF
                            01_2563)</span>
                        </v-flex>
                      </v-layout>
                      <v-layout mt-4>
                        <v-flex xs7>
                          <v-layout>
                            <v-flex shrink pt-2 pr-4 style="text-align: left;">
                              <span style="font-weight: bold;font-size: 16px;">ไฟล์</span>
                            </v-flex>
                            <v-flex>
                              <v-file-input accept=".pdf" prepend-icon="" id="submitReportForConsider"
                                :model="form.researchs.submitReportForConsider" outlined dense background-color="#FBF6F3"
                                color="#3E7662" item-color="#3E7662"
                                @change="uploadFileChange('submitReportForConsider', $event)" hide-details></v-file-input>
                            </v-flex>
                            <v-flex shrink pl-4 style="text-align: left">
                              <v-btn color="#3E7662" dark @click="uploadFile('submitReportForConsider')">
                                <v-icon class="pr-3">cloud_download</v-icon>
                                <span>Upload</span>
                              </v-btn>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs1></v-flex>
                      </v-layout>
                      <v-layout mt-1 v-if="researchID !== 0">
                        <v-flex style="font-size: 12px;color: #CC3D45" ml-10 class="text-start">
                          <span>ชื่อไฟล์ที่อัพโหลด : </span>
                          <span class="link__pdf" @click="openPDF('submitReportForConsiderPath')">{{ namePDF15 ? namePDF15
                            : "-"
                          }}</span>
                          <v-icon v-if="form.researchs.submitReportForConsider"
                            @click="cancelFile('submitReportForConsider', 'namePDF15')"
                            style="cursor: pointer">mdi-close</v-icon>
                        </v-flex>
                      </v-layout>
                      <v-layout v-if="verifyForm.noteFive">
                        <v-flex xs7 pa-2 style="border: #CC3D45 solid 1px">
                          <span>รายการแก้ไขเพิ่มเติม แบบรายงานการส่งโครงร่างวิจัยเพื่อพิจารณา(RF 01_2563):</span><br>
                          <span style="color: #CC3D45">{{ verifyForm.noteFive }}</span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </div>
                <div v-if="submissionType === '1' || submissionType === '2'">
                  <!--Upload1-->
                  <v-layout mt-6>
                    <v-flex xs1></v-flex>
                    <v-flex xs11>
                      <v-layout>
                        <v-flex>
                          <span
                            style="font-weight: bold;font-size: 19px;color: #CC3D45;">หนังสือรับรองจากผู้บังคับบัญชาระดับ
                            ผอ.กอง หรือ
                            หัวหน้าภาควิชา*</span>
                        </v-flex>
                      </v-layout>
                      <v-layout mt-4>
                        <v-flex xs7>
                          <v-layout>
                            <v-flex shrink pt-2 pr-4 style="text-align: left;">
                              <span style="font-weight: bold;font-size: 16px;">ไฟล์</span>
                            </v-flex>
                            <v-flex>
                              <v-file-input accept=".pdf" prepend-icon="" id="guarantyFile"
                                :model="form.researchs.guarantyFile" outlined dense background-color="#FBF6F3"
                                color="#3E7662" item-color="#3E7662" @change="
                                  uploadFileChange('guarantyFile', $event)
                                  " hide-details></v-file-input>
                            </v-flex>
                            <v-flex shrink pl-4 style="text-align: left">
                              <v-btn color="#3E7662" dark @click="uploadFile('guarantyFile')">
                                <v-icon class="pr-3">cloud_download</v-icon>
                                <span>Upload</span>
                              </v-btn>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs1></v-flex>
                        <v-flex xs4>
                          <v-layout>
                            <v-flex shrink pt-2>
                              <span class="font-weight-bold">*เลขหนังสือ</span>
                            </v-flex>
                            <v-flex pl-2 xs6>
                              <TextfieldForm :hide-detail="true" :model="form.researchs.guarantyNumber" @update-data="
                                updateDataResearchs('guarantyNumber', $event)
                                "></TextfieldForm>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                      <v-layout mt-1 v-if="researchID !== 0">
                        <v-flex style="font-size: 12px;color: #CC3D45" ml-10 class="text-start">
                          <span>ชื่อไฟล์ที่อัพโหลด : </span>
                          <span class="link__pdf" @click="openPDF('guarantyFilePath')">{{ namePDF1 }}</span>
                        </v-flex>
                      </v-layout>
                      <v-layout v-if="verifyForm.noteOne">
                        <v-flex xs7 pa-2 style="border: #CC3D45 solid 1px">
                          <span>รายการแก้ไขเพิ่มเติม หนังสือรับรองจากผู้บังคับบัญชาระดับ ผอ.กอง หรือ
                            หัวหน้าภาควิชา:</span><br>
                          <span style="color: #CC3D45">{{ verifyForm.noteOne }}</span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                  <!--research  clean-->
                  <v-layout mt-6>
                    <v-flex xs1></v-flex>
                    <v-flex xs11>
                      <v-layout>
                        <v-flex>
                          <span style="font-weight: bold;font-size: 19px;color: #CC3D45;">อัพโหลดเอกสารโครงร่างงานวิจัย
                            Clean Version (RF 08_2_2563)</span>
                        </v-flex>
                      </v-layout>
                      <v-layout mt-4>
                        <v-flex xs7>
                          <v-layout>
                            <v-flex shrink pt-2 pr-4 style="text-align: left;">
                              <span style="font-weight: bold;font-size: 16px;">ไฟล์</span>
                            </v-flex>
                            <v-flex>
                              <v-file-input accept=".pdf" prepend-icon="" id="researchFileClean"
                                :model="form.researchs.researchFileClean" outlined dense background-color="#FBF6F3"
                                color="#3E7662" item-color="#3E7662" @change="
                                  uploadFileChange('researchFileClean', $event)
                                  " hide-details></v-file-input>
                            </v-flex>
                            <v-flex shrink pl-4 style="text-align: left">
                              <v-btn color="#3E7662" dark @click="uploadFile('researchFileClean')">
                                <v-icon class="pr-3">cloud_download</v-icon>
                                <span>Upload</span>
                              </v-btn>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs1></v-flex>
                      </v-layout>
                      <v-layout mt-1 v-if="researchID !== 0">
                        <v-flex style="font-size: 12px;color: #CC3D45" ml-10 class="text-start">
                          <span>ชื่อไฟล์ที่อัพโหลด : </span>
                          <span class="link__pdf" @click="openPDF('researchFileCleanPath')">{{ namePDF7 }}</span>
                          <v-icon v-if="form.researchs.crecFile" @click="cancelFile('researchFileClean', 'namePDF7')"
                            style="cursor: pointer">mdi-close</v-icon>
                        </v-flex>
                      </v-layout>
                      <v-layout v-if="verifyForm.noteTwo">
                        <v-flex xs7 pa-2 style="border: #CC3D45 solid 1px">
                          <span>รายการแก้ไขเพิ่มเติม เอกสารโครงร่างงานวิจัย (RF 08_2_2563):</span><br>
                          <span style="color: #CC3D45">{{ verifyForm.noteTwo }}</span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                  <v-layout mt-6>
                    <v-flex xs1></v-flex>
                    <v-flex xs11>
                      <v-layout>
                        <v-flex>
                          <span style="font-weight: bold;font-size: 19px;color: #CC3D45;">อัพโหลดเอกสารโครงร่างงานวิจัย
                            Highlight Version (RF 08_2_2563)</span>
                        </v-flex>
                      </v-layout>
                      <v-layout mt-4>
                        <v-flex xs7>
                          <v-layout>
                            <v-flex shrink pt-2 pr-4 style="text-align: left;">
                              <span style="font-weight: bold;font-size: 16px;">ไฟล์</span>
                            </v-flex>
                            <v-flex>
                              <v-file-input accept=".pdf" prepend-icon="" id="researchFileHighlight"
                                :model="form.researchs.researchFileHighlight" outlined dense background-color="#FBF6F3"
                                color="#3E7662" item-color="#3E7662" @change="
                                  uploadFileChange(
                                    'researchFileHighlight',
                                    $event
                                  )
                                  " hide-details></v-file-input>
                            </v-flex>
                            <v-flex shrink pl-4 style="text-align: left">
                              <v-btn color="#3E7662" dark @click="uploadFile('researchFileHighlight')">
                                <v-icon class="pr-3">cloud_download</v-icon>
                                <span>Upload</span>
                              </v-btn>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs1></v-flex>
                      </v-layout>
                      <v-layout mt-1 v-if="researchID !== 0">
                        <v-flex style="font-size: 12px;color: #CC3D45" ml-10 class="text-start">
                          <span>ชื่อไฟล์ที่อัพโหลด : </span>
                          <span class="link__pdf" @click="openPDF('researchFileHighlightPath')">{{ namePDF8 }}</span>
                          <v-icon v-if="form.researchs.crecFile" @click="cancelFile('researchFileHighlight', 'namePDF8')"
                            style="cursor: pointer">mdi-close</v-icon>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                  <!--ro01.1 clean-->
                  <v-layout mt-6>
                    <v-flex xs1></v-flex>
                    <v-flex xs11>
                      <v-layout>
                        <v-flex>
                          <span
                            style="font-weight: bold;font-size: 19px;color: #CC3D45;">หนังสือข้อตกลงรักษาความลับของข้อมูลฯ
                            (RO01_1_2563)</span>
                        </v-flex>
                      </v-layout>
                      <v-layout mt-4>
                        <v-flex xs7>
                          <v-layout>
                            <v-flex shrink pt-2 pr-4 style="text-align: left;">
                              <span style="font-weight: bold;font-size: 16px;">ไฟล์</span>
                            </v-flex>
                            <v-flex>
                              <v-file-input accept=".pdf" prepend-icon="" id="ro01_1FileClean"
                                :model="form.researchs.ro01_1FileClean" outlined dense background-color="#FBF6F3"
                                color="#3E7662" item-color="#3E7662" @change="
                                  uploadFileChange('ro01_1FileClean', $event)
                                  " hide-details></v-file-input>
                            </v-flex>
                            <v-flex shrink pl-4 style="text-align: left">
                              <v-btn color="#3E7662" dark @click="uploadFile('ro01_1FileClean')">
                                <v-icon class="pr-3">cloud_download</v-icon>
                                <span>Upload</span>
                              </v-btn>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                      <v-layout mt-1 v-if="researchID !== 0">
                        <v-flex style="font-size: 12px;color: #CC3D45" ml-10 class="text-start">
                          <span>ชื่อไฟล์ที่อัพโหลด : </span>
                          <span class="link__pdf" @click="openPDF('ro01_1FileCleanPath')">{{ namePDF9 }}</span>
                          <v-icon v-if="form.researchs.crecFile" @click="cancelFile('ro01_1FileClean', 'namePDF9')"
                            style="cursor: pointer">mdi-close</v-icon>
                        </v-flex>
                      </v-layout>
                      <v-layout v-if="verifyForm.noteThree">
                        <v-flex xs7 pa-2 style="border: #CC3D45 solid 1px">
                          <span>รายการแก้ไขเพิ่มเติม หนังสือข้อตกลงรักษาความลับของข้อมูลฯ (RO01_1_2563):</span><br>
                          <span style="color: #CC3D45">{{ verifyForm.noteThree }}</span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                  <!--ro01.2 clean-->
                  <v-layout mt-6>
                    <v-flex xs1></v-flex>
                    <v-flex xs11>
                      <v-layout>
                        <v-flex>
                          <span
                            style="font-weight: bold;font-size: 19px;color: #CC3D45;">หนังสือข้อตกลงในการดำเนินการวิจัย
                            (RO01_2_2563)</span>
                        </v-flex>
                      </v-layout>
                      <v-layout mt-4>
                        <v-flex xs7>
                          <v-layout>
                            <v-flex shrink pt-2 pr-4 style="text-align: left;">
                              <span style="font-weight: bold;font-size: 16px;">ไฟล์</span>
                            </v-flex>
                            <v-flex>
                              <v-file-input accept=".pdf" prepend-icon="" id="ro01_2FileClean"
                                :model="form.researchs.ro01_2FileClean" outlined dense background-color="#FBF6F3"
                                color="#3E7662" item-color="#3E7662" @change="
                                  uploadFileChange('ro01_2FileClean', $event)
                                  " hide-details></v-file-input>
                            </v-flex>
                            <v-flex shrink pl-4 style="text-align: left">
                              <v-btn color="#3E7662" dark @click="uploadFile('ro01_2FileClean')">
                                <v-icon class="pr-3">cloud_download</v-icon>
                                <span>Upload</span>
                              </v-btn>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs1></v-flex>
                      </v-layout>
                      <v-layout mt-1 v-if="researchID !== 0">
                        <v-flex style="font-size: 12px;color: #CC3D45" ml-10 class="text-start">
                          <span>ชื่อไฟล์ที่อัพโหลด : </span>
                          <span class="link__pdf" @click="openPDF('ro01_2FileCleanPath')">{{ namePDF11 }}</span>
                          <v-icon v-if="form.researchs.crecFile" @click="cancelFile('ro01_2FileClean', 'namePDF11')"
                            style="cursor: pointer">mdi-close</v-icon>
                        </v-flex>
                      </v-layout>
                      <v-layout v-if="verifyForm.noteThree">
                        <v-flex xs7 pa-2 style="border: #CC3D45 solid 1px">
                          <span>รายการแก้ไขเพิ่มเติม หนังสือข้อตกลงในการดำเนินการวิจัย (RO01_2_2563):</span><br>
                          <span style="color: #CC3D45">{{ verifyForm.noteThree }}</span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                  <!--Upload5-->
                  <v-layout mt-6>
                    <v-flex xs1></v-flex>
                    <v-flex xs11>
                      <v-layout>
                        <v-flex>
                          <span style="font-weight: bold;font-size: 19px;color: #CC3D45;">เอกสารรับรองโครงร่างการวิจัย จาก
                            CREC/YMID
                            (ถ้ามี)</span>
                        </v-flex>
                      </v-layout>
                      <v-layout mt-4>
                        <v-flex xs7>
                          <v-layout>
                            <v-flex shrink pt-2 pr-4 style="text-align: left;">
                              <span style="font-weight: bold;font-size: 16px;">ไฟล์</span>
                            </v-flex>
                            <v-flex>
                              <v-file-input accept=".pdf" prepend-icon="" id="crecFile2" :model="form.researchs.crecFile2"
                                outlined dense background-color="#FBF6F3" color="#3E7662" item-color="#3E7662"
                                @change="uploadFileChange('crecFile2', $event)" hide-details></v-file-input>
                            </v-flex>
                            <v-flex shrink pl-4 style="text-align: left">
                              <v-btn color="#3E7662" dark @click="uploadFile('crecFile2')">
                                <v-icon class="pr-3">cloud_download</v-icon>
                                <span>Upload</span>
                              </v-btn>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs1></v-flex>
                      </v-layout>
                      <v-layout mt-1 v-if="researchID !== 0">
                        <v-flex style="font-size: 12px;color: #CC3D45" ml-10 class="text-start">
                          <span>ชื่อไฟล์ที่อัพโหลด : </span>
                          <span class="link__pdf" @click="openPDF('crecFile2Path')">{{ namePDF14 }}</span>
                          <v-icon v-if="form.researchs.crecFile" @click="cancelFile('crecFile2', 'namePDF14')"
                            style="cursor: pointer">mdi-close</v-icon>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                  <!--Upload6-->
                  <v-layout mt-6>
                    <v-flex xs1></v-flex>
                    <v-flex xs11>
                      <v-layout>
                        <v-flex>
                          <span style="font-weight: bold;font-size: 19px;color: #CC3D45;">แบบรายการแก้ไขโครงร่างวิจัย (RF
                            02_2563)*</span>
                        </v-flex>
                      </v-layout>
                      <v-layout mt-4>
                        <v-flex xs7>
                          <v-layout>
                            <v-flex shrink pt-2 pr-4 style="text-align: left;">
                              <span style="font-weight: bold;font-size: 16px;">ไฟล์</span>
                            </v-flex>
                            <v-flex>
                              <v-file-input accept=".pdf" prepend-icon="" id="editPointFile"
                                :model="form.researchs.editPointFile" outlined dense background-color="#FBF6F3"
                                color="#3E7662" item-color="#3E7662" @change="
                                  uploadFileChange('editPointFile', $event)
                                  " hide-details></v-file-input>
                            </v-flex>
                            <v-flex shrink pl-4 style="text-align: left">
                              <v-btn color="#3E7662" dark @click="uploadFile('editPointFile')">
                                <v-icon class="pr-3">cloud_download</v-icon>
                                <span>Upload</span>
                              </v-btn>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs1></v-flex>
                      </v-layout>
                      <v-layout mt-1 v-if="researchID !== 0">
                        <v-flex style="font-size: 12px;color: #CC3D45" ml-10 class="text-start">
                          <span>ชื่อไฟล์ที่อัพโหลด : </span>
                          <span class="link__pdf" @click="openPDF('editPointFilePath')">{{ namePDF6 }}</span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                  <!--เอกสารแสดงผลการพิจารณา-->
                  <v-layout mt-6>
                    <v-flex xs1></v-flex>
                    <v-flex xs11>
                      <v-layout>
                        <v-flex>
                          <span style="font-weight: bold;font-size: 19px;color: #CC3D45;">เอกสารแสดงผลการพิจารณา (RL
                            02_1_2563)
                          </span>
                        </v-flex>
                      </v-layout>
                      <v-layout mt-4>
                        <v-flex xs7>
                          <v-layout>
                            <v-flex shrink pt-2 pr-4 style="text-align: left;">
                              <span style="font-weight: bold;font-size: 16px;">ไฟล์</span>
                            </v-flex>
                            <v-flex>
                              <v-file-input accept=".pdf" prepend-icon="" id="considerResultFile"
                                :model="form.researchs.considerResultFile" outlined dense background-color="#FBF6F3"
                                color="#3E7662" item-color="#3E7662" @change="
                                  uploadFileChange('considerResultFile', $event)
                                  " hide-details></v-file-input>
                            </v-flex>
                            <v-flex shrink pl-4 style="text-align: left">
                              <v-btn color="#3E7662" dark @click="uploadFile('considerResultFile')">
                                <v-icon class="pr-3">cloud_download</v-icon>
                                <span>Upload</span>
                              </v-btn>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs1></v-flex>
                      </v-layout>
                      <v-layout mt-1 v-if="researchID !== 0">
                        <v-flex style="font-size: 12px;color: #CC3D45" ml-10 class="text-start">
                          <span>ชื่อไฟล์ที่อัพโหลด : </span>
                          <span class="link__pdf" @click="openPDF('considerResultFilePath')">{{ namePDF13 }}</span>
                          <v-icon v-if="form.researchs.crecFile" @click="cancelFile('considerResultFile', 'namePDF13')"
                            style="cursor: pointer">mdi-close</v-icon>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                  <!--แบบรายงานการส่งโครงร่างวิจัยเพื่อพิจารณา Clean Version (RF 01_2563)-->
                  <v-layout mt-6>
                    <v-flex xs1></v-flex>
                    <v-flex xs11>
                      <v-layout>
                        <v-flex>
                          <span style="font-weight: bold;font-size: 19px;">แบบรายงานการส่งโครงร่างวิจัยเพื่อพิจารณา Clean
                            Version (RF 01_2563)</span>
                        </v-flex>
                      </v-layout>
                      <v-layout mt-4>
                        <v-flex xs7>
                          <v-layout>
                            <v-flex shrink pt-2 pr-4 style="text-align: left;">
                              <span style="font-weight: bold;font-size: 16px;">ไฟล์</span>
                            </v-flex>
                            <v-flex>
                              <v-file-input accept=".pdf" prepend-icon="" id="submitReportForConsiderClean"
                                :model="form.researchs.submitReportForConsiderClean" outlined dense
                                background-color="#FBF6F3" color="#3E7662" item-color="#3E7662"
                                @change="uploadFileChange('submitReportForConsiderClean', $event)"
                                hide-details></v-file-input>
                            </v-flex>
                            <v-flex shrink pl-4 style="text-align: left">
                              <v-btn color="#3E7662" dark @click="uploadFile('submitReportForConsiderClean')">
                                <v-icon class="pr-3">cloud_download</v-icon>
                                <span>Upload</span>
                              </v-btn>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs1></v-flex>
                      </v-layout>
                      <v-layout mt-1 v-if="researchID !== 0">
                        <v-flex style="font-size: 12px;color: #CC3D45" ml-10 class="text-start">
                          <span>ชื่อไฟล์ที่อัพโหลด : </span>
                          <span class="link__pdf" @click="openPDF('submitReportForConsiderCleanPath')">{{ namePDF16
                          }}</span>
                          <v-icon v-if="form.researchs.submitReportForConsiderClean"
                            @click="cancelFile('submitReportForConsiderClean', 'namePDF16Clean')"
                            style="cursor: pointer">mdi-close</v-icon>
                        </v-flex>
                      </v-layout>
                      <v-layout v-if="verifyForm.noteFive">
                        <v-flex xs7 pa-2 style="border: #CC3D45 solid 1px">
                          <span>รายการแก้ไขเพิ่มเติม แบบรายงานการส่งโครงร่างวิจัยเพื่อพิจารณา(RF 01_2563):</span><br>
                          <span style="color: #CC3D45">{{ verifyForm.noteFive }}</span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                  <!--แบบรายงานการส่งโครงร่างวิจัยเพื่อพิจารณา Highlight Version (RF 01_2563)-->
                  <v-layout mt-6>
                    <v-flex xs1></v-flex>
                    <v-flex xs11>
                      <v-layout>
                        <v-flex>
                          <span style="font-weight: bold;font-size: 19px;">แบบรายงานการส่งโครงร่างวิจัยเพื่อพิจารณา
                            Highlight Version (RF 01_2563)</span>
                        </v-flex>
                      </v-layout>
                      <v-layout mt-4>
                        <v-flex xs7>
                          <v-layout>
                            <v-flex shrink pt-2 pr-4 style="text-align: left;">
                              <span style="font-weight: bold;font-size: 16px;">ไฟล์</span>
                            </v-flex>
                            <v-flex>
                              <v-file-input accept=".pdf" prepend-icon="" id="submitReportForConsiderHighlight"
                                :model="form.researchs.submitReportForConsiderHighlight" outlined dense
                                background-color="#FBF6F3" color="#3E7662" item-color="#3E7662"
                                @change="uploadFileChange('submitReportForConsiderHighlight', $event)"
                                hide-details></v-file-input>
                            </v-flex>
                            <v-flex shrink pl-4 style="text-align: left">
                              <v-btn color="#3E7662" dark @click="uploadFile('submitReportForConsiderHighlight')">
                                <v-icon class="pr-3">cloud_download</v-icon>
                                <span>Upload</span>
                              </v-btn>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs1></v-flex>
                      </v-layout>
                      <v-layout mt-1 v-if="researchID !== 0">
                        <v-flex style="font-size: 12px;color: #CC3D45" ml-10 class="text-start">
                          <span>ชื่อไฟล์ที่อัพโหลด : </span>
                          <span class="link__pdf" @click="openPDF('submitReportForConsiderHighlightPath')">{{ namePDF17
                          }}</span>
                          <v-icon v-if="form.researchs.submitReportForConsiderHighlight"
                            @click="cancelFile('submitReportForConsiderHighlight', 'namePDF17')"
                            style="cursor: pointer">mdi-close</v-icon>
                        </v-flex>
                      </v-layout>
                      <v-layout v-if="verifyForm.noteFive">
                        <v-flex xs7 pa-2 style="border: #CC3D45 solid 1px">
                          <span>รายการแก้ไขเพิ่มเติม แบบรายงานการส่งโครงร่างวิจัยเพื่อพิจารณา(RF 01_2563):</span><br>
                          <span style="color: #CC3D45">{{ verifyForm.noteFive }}</span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </div>
                <div v-if="submissionType === '999'">
                  <!--Upload1-->
                  <v-layout mt-6>
                    <v-flex xs1></v-flex>
                    <v-flex xs11>
                      <v-layout>
                        <v-flex>
                          <span
                            style="font-weight: bold;font-size: 19px;color: #CC3D45;">หนังสือรับรองจากผู้บังคับบัญชาระดับ
                            ผอ.กอง หรือ
                            หัวหน้าภาควิชา*</span>
                        </v-flex>
                      </v-layout>
                      <v-layout mt-4>
                        <v-flex xs7>
                          <v-layout>
                            <v-flex shrink pt-2 pr-4 style="text-align: left;">
                              <span style="font-weight: bold;font-size: 16px;">ไฟล์</span>
                            </v-flex>
                            <v-flex>
                              <v-file-input accept=".pdf" prepend-icon="" id="guarantyFile"
                                :model="form.researchs.guarantyFile" outlined dense background-color="#FBF6F3"
                                color="#3E7662" item-color="#3E7662" @change="
                                  uploadFileChange('guarantyFile', $event)
                                  " hide-details></v-file-input>
                            </v-flex>
                            <v-flex shrink pl-4 style="text-align: left">
                              <v-btn color="#3E7662" dark @click="uploadFile('guarantyFile')">
                                <v-icon class="pr-3">cloud_download</v-icon>
                                <span>Upload</span>
                              </v-btn>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs1></v-flex>
                        <v-flex xs4>
                          <v-layout>
                            <v-flex shrink pt-2>
                              <span class="font-weight-bold">*เลขหนังสือ</span>
                            </v-flex>
                            <v-flex pl-2 xs6>
                              <TextfieldForm :hide-detail="true" :model="form.researchs.guarantyNumber" @update-data="
                                updateDataResearchs('guarantyNumber', $event)
                                "></TextfieldForm>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                      <v-layout mt-1 v-if="researchID !== 0">
                        <v-flex style="font-size: 12px;color: #CC3D45" ml-10 class="text-start">
                          <span>ชื่อไฟล์ที่อัพโหลด : </span>
                          <span class="link__pdf" @click="openPDF('guarantyFilePath')">{{ namePDF1 }}</span>
                        </v-flex>
                      </v-layout>
                      <v-layout v-if="verifyForm.noteOne">
                        <v-flex xs7 pa-2 style="border: #CC3D45 solid 1px">
                          <span>รายการแก้ไขเพิ่มเติม หนังสือรับรองจากผู้บังคับบัญชาระดับ ผอ.กอง หรือ
                            หัวหน้าภาควิชา:</span><br>
                          <span style="color: #CC3D45">{{ verifyForm.noteOne }}</span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                  <!--Upload2-->
                  <v-layout mt-6>
                    <v-flex xs1></v-flex>
                    <v-flex xs11>
                      <v-layout>
                        <v-flex>
                          <span style="font-weight: bold;font-size: 19px;color: #CC3D45;">เอกสารโครงร่างงานวิจัย (RF
                            08_2_2563)*</span>
                        </v-flex>
                      </v-layout>
                      <v-layout mt-4>
                        <v-flex xs7>
                          <v-layout>
                            <v-flex shrink pt-2 pr-4 style="text-align: left;">
                              <span style="font-weight: bold;font-size: 16px;">ไฟล์</span>
                            </v-flex>
                            <v-flex>
                              <v-file-input accept=".pdf" prepend-icon="" id="researchFile"
                                :model="form.researchs.researchFile" outlined dense background-color="#FBF6F3"
                                color="#3E7662" item-color="#3E7662" @change="
                                  uploadFileChange('researchFile', $event)
                                  " hide-details></v-file-input>
                            </v-flex>
                            <v-flex shrink pl-4 style="text-align: left">
                              <v-btn color="#3E7662" dark @click="uploadFile('researchFile')">
                                <v-icon class="pr-3">cloud_download</v-icon>
                                <span>Upload</span>
                              </v-btn>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex></v-flex>
                      </v-layout>
                      <v-layout mt-1 v-if="researchID !== 0">
                        <v-flex style="font-size: 12px;color: #CC3D45" ml-10 class="text-start">
                          <span>ชื่อไฟล์ที่อัพโหลด : </span>
                          <span class="link__pdf" @click="openPDF('researchFilePath')">{{ namePDF2 }}</span>
                        </v-flex>
                      </v-layout>
                      <v-layout v-if="verifyForm.noteTwo">
                        <v-flex xs7 pa-2 style="border: #CC3D45 solid 1px">
                          <span>รายการแก้ไขเพิ่มเติม เอกสารโครงร่างงานวิจัย (RF 08_2_2563):</span><br>
                          <span style="color: #CC3D45">{{ verifyForm.noteTwo }}</span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                  <!--Upload3-->
                  <v-layout mt-6>
                    <v-flex xs1></v-flex>
                    <v-flex xs11>
                      <v-layout>
                        <v-flex>
                          <span
                            style="font-weight: bold;font-size: 19px;color: #CC3D45;">หนังสือข้อตกลงรักษาความลับของข้อมูลฯ
                            (RO01_1_2563)*</span>
                        </v-flex>
                      </v-layout>
                      <v-layout mt-4>
                        <v-flex xs7>
                          <v-layout>
                            <v-flex shrink pt-2 pr-4 style="text-align: left;">
                              <span style="font-weight: bold;font-size: 16px;">ไฟล์</span>
                            </v-flex>
                            <v-flex>
                              <v-file-input accept=".pdf" prepend-icon="" id="ro01_1File"
                                :model="form.researchs.ro01_1File" outlined dense background-color="#FBF6F3"
                                color="#3E7662" item-color="#3E7662" @change="uploadFileChange('ro01_1File', $event)"
                                hide-details></v-file-input>
                            </v-flex>
                            <v-flex shrink pl-4 style="text-align: left">
                              <v-btn color="#3E7662" dark @click="uploadFile('ro01_1File')">
                                <v-icon class="pr-3">cloud_download</v-icon>
                                <span>Upload</span>
                              </v-btn>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                      <v-layout mt-1>
                        <v-flex style="font-size: 12px;color: #CC3D45" ml-10 class="text-start">
                          <b>หมายเหตุ: ถ้ามีมากกว่า 1 ไฟล์ ให้รวมไฟล์ </b>
                        </v-flex>
                      </v-layout>
                      <v-layout mt-1 v-if="researchID !== 0">
                        <v-flex style="font-size: 12px;color: #CC3D45" ml-10 class="text-start">
                          <span>ชื่อไฟล์ที่อัพโหลด : </span>
                          <span class="link__pdf" @click="openPDF('ro01_1FilePath')">{{ namePDF3 }}</span>
                        </v-flex>
                      </v-layout>
                      <v-layout v-if="verifyForm.noteThree">
                        <v-flex xs7 pa-2 style="border: #CC3D45 solid 1px">
                          <span>รายการแก้ไขเพิ่มเติม หนังสือข้อตกลงรักษาความลับของข้อมูลฯ (RO01_1_2563):</span><br>
                          <span style="color: #CC3D45">{{ verifyForm.noteThree }}</span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                  <!--Upload4-->
                  <v-layout mt-6>
                    <v-flex xs1></v-flex>
                    <v-flex xs11>
                      <v-layout>
                        <v-flex>
                          <span
                            style="font-weight: bold;font-size: 19px;color: #CC3D45;">หนังสือข้อตกลงในการดำเนินการวิจัย
                            (RO01_2_2563)*</span>
                        </v-flex>
                      </v-layout>
                      <v-layout mt-4>
                        <v-flex xs7>
                          <v-layout>
                            <v-flex shrink pt-2 pr-4 style="text-align: left;">
                              <span style="font-weight: bold;font-size: 16px;">ไฟล์</span>
                            </v-flex>
                            <v-flex>
                              <v-file-input accept=".pdf" prepend-icon="" id="ro01_2File"
                                :model="form.researchs.ro01_2File" outlined dense background-color="#FBF6F3"
                                color="#3E7662" item-color="#3E7662" @change="uploadFileChange('ro01_2File', $event)"
                                hide-details></v-file-input>
                            </v-flex>
                            <v-flex shrink pl-4 style="text-align: left">
                              <v-btn color="#3E7662" dark @click="uploadFile('ro01_2File')">
                                <v-icon class="pr-3">cloud_download</v-icon>
                                <span>Upload</span>
                              </v-btn>
                            </v-flex>
                          </v-layout>
                          <v-layout mt-1>
                            <v-flex style="font-size: 12px;color: #CC3D45" ml-10 class="text-start">
                              <b>หมายเหตุ: ถ้ามีมากกว่า 1 ไฟล์ ให้รวมไฟล์ </b>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs1></v-flex>
                      </v-layout>
                      <v-layout mt-1 v-if="researchID !== 0">
                        <v-flex style="font-size: 12px;color: #CC3D45" ml-10 class="text-start">
                          <span>ชื่อไฟล์ที่อัพโหลด : </span>
                          <span class="link__pdf" @click="openPDF('ro01_2FilePath')">{{ namePDF4 }}</span>
                        </v-flex>
                      </v-layout>
                      <v-layout v-if="verifyForm.noteThree">
                        <v-flex xs7 pa-2 style="border: #CC3D45 solid 1px">
                          <span>รายการแก้ไขเพิ่มเติม หนังสือข้อตกลงในการดำเนินการวิจัย (RO01_2_2563):</span><br>
                          <span style="color: #CC3D45">{{ verifyForm.noteThree }}</span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                  <!--Upload5-->
                  <v-layout mt-6>
                    <v-flex xs1></v-flex>
                    <v-flex xs11>
                      <v-layout>
                        <v-flex>
                          <span style="font-weight: bold;font-size: 19px;">เอกสารรับรองโครงร่างการวิจัย จาก CREC/YMID
                            (ถ้ามี)</span>
                        </v-flex>
                      </v-layout>
                      <v-layout mt-4>
                        <v-flex xs7>
                          <v-layout>
                            <v-flex shrink pt-2 pr-4 style="text-align: left;">
                              <span style="font-weight: bold;font-size: 16px;">ไฟล์</span>
                            </v-flex>
                            <v-flex>
                              <v-file-input accept=".pdf" prepend-icon="" id="crecFile" :model="form.researchs.crecFile"
                                outlined dense background-color="#FBF6F3" color="#3E7662" item-color="#3E7662"
                                @change="uploadFileChange('crecFile', $event)" hide-details></v-file-input>
                            </v-flex>
                            <v-flex shrink pl-4 style="text-align: left">
                              <v-btn color="#3E7662" dark @click="uploadFile('crecFile')">
                                <v-icon class="pr-3">cloud_download</v-icon>
                                <span>Upload</span>
                              </v-btn>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs1></v-flex>
                      </v-layout>
                      <v-layout mt-1 v-if="researchID !== 0">
                        <v-flex style="font-size: 12px;color: #CC3D45" ml-10 class="text-start">
                          <span>ชื่อไฟล์ที่อัพโหลด : </span>
                          <span class="link__pdf" @click="openPDF('crecFilePath')">{{ namePDF5 }}</span>
                          <v-icon v-if="form.researchs.crecFile" @click="cancelFile('crecFile', 'namePDF5')"
                            style="cursor: pointer">mdi-close</v-icon>
                        </v-flex>
                      </v-layout>
                      <v-layout v-if="verifyForm.noteFour">
                        <v-flex xs7 pa-2 style="border: #CC3D45 solid 1px">
                          <span>รายการแก้ไขเพิ่มเติม เอกสารรับรองโครงร่างการวิจัย จาก CREC/YMID:</span><br>
                          <span style="color: #CC3D45">{{ verifyForm.noteFour }}</span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                  <!--Upload6-->
                  <v-layout mt-6>
                    <v-flex xs1></v-flex>
                    <v-flex xs11>
                      <v-layout>
                        <v-flex>
                          <span style="font-weight: bold;font-size: 19px;">แบบรายการแก้ไขโครงร่างวิจัย (RF 02_2563)</span>
                        </v-flex>
                      </v-layout>
                      <v-layout mt-4>
                        <v-flex xs7>
                          <v-layout>
                            <v-flex shrink pt-2 pr-4 style="text-align: left;">
                              <span style="font-weight: bold;font-size: 16px;">ไฟล์</span>
                            </v-flex>
                            <v-flex>
                              <v-file-input accept=".pdf" prepend-icon="" id="editPointFile"
                                :model="form.researchs.editPointFile" outlined dense background-color="#FBF6F3"
                                color="#3E7662" item-color="#3E7662" @change="
                                  uploadFileChange('editPointFile', $event)
                                  " hide-details></v-file-input>
                            </v-flex>
                            <v-flex shrink pl-4 style="text-align: left">
                              <v-btn color="#3E7662" dark @click="uploadFile('editPointFile')">
                                <v-icon class="pr-3">cloud_download</v-icon>
                                <span>Upload</span>
                              </v-btn>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs1></v-flex>
                      </v-layout>
                      <v-layout mt-1 v-if="researchID !== 0">
                        <v-flex style="font-size: 12px;color: #CC3D45" ml-10 class="text-start">
                          <span>ชื่อไฟล์ที่อัพโหลด : </span>
                          <span class="link__pdf" @click="openPDF('editPointFilePath')">{{ namePDF6 }}</span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>

                  <v-layout mt-6>
                    <v-flex xs1></v-flex>
                    <v-flex xs11>
                      <v-layout>
                        <v-flex>
                          <span style="font-weight: bold;font-size: 19px;color: #CC3D45;">เอกสารแสดงผลการพิจารณา (RL
                            02_1_2563)
                          </span>
                        </v-flex>
                      </v-layout>
                      <v-layout mt-4>
                        <v-flex xs7>
                          <v-layout>
                            <v-flex shrink pt-2 pr-4 style="text-align: left;">
                              <span style="font-weight: bold;font-size: 16px;">ไฟล์</span>
                            </v-flex>
                            <v-flex>
                              <v-file-input accept=".pdf" prepend-icon="" id="considerResultFile"
                                :model="form.researchs.considerResultFile" outlined dense background-color="#FBF6F3"
                                color="#3E7662" item-color="#3E7662" @change="
                                  uploadFileChange('considerResultFile', $event)
                                  " hide-details></v-file-input>
                            </v-flex>
                            <v-flex shrink pl-4 style="text-align: left">
                              <v-btn color="#3E7662" dark @click="uploadFile('considerResultFile')">
                                <v-icon class="pr-3">cloud_download</v-icon>
                                <span>Upload</span>
                              </v-btn>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs1></v-flex>
                      </v-layout>
                      <v-layout mt-1 v-if="researchID !== 0">
                        <v-flex style="font-size: 12px;color: #CC3D45" ml-10 class="text-start">
                          <span>ชื่อไฟล์ที่อัพโหลด : </span>
                          <span class="link__pdf" @click="openPDF('considerResultFilePath')">{{ namePDF13 }}</span>
                          <v-icon v-if="form.researchs.crecFile" @click="cancelFile('considerResultFile', 'namePDF13')"
                            style="cursor: pointer">mdi-close</v-icon>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </div>

                <v-layout pt-12>
                  <v-flex style="text-align: left;">
                    <span>สำนักงานคณะอนุกรรมการพิจารณาโครงการวิจัย พบ.</span>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-form>
        </v-card>
      </v-flex>
    </v-layout>

    <v-layout>
      <v-flex mb-12 style="padding: 0 5% 2% 5%; text-align: right;">
        <v-layout>
          <v-flex>
            <v-btn style="color: #3E7662" class="mx-4" depressed color="#ffffff"
              @click="$router.push('/manual-research')">ย้อนกลับ
            </v-btn>
            <v-btn depressed :disabled="!valid" :dark="valid" color="#3E7662" @click="changePage">
              <v-icon class="mr-3">add</v-icon>
              <span>ถัดไป</span>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>

    <!--create new budget modal-->
    <template>
      <v-row justify="center">
        <v-dialog v-model="budgetDialog" persistent max-width="600px">
          <v-card>
            <!-- Header -->
            <v-card-title class="mb-4">
              <span>เพิ่มแหล่งทุนวิจัย</span>
            </v-card-title>
            <v-card-text>
              <!-- row 1 -->
              <v-layout px-6>
                <v-flex xs3 mr-3 justify-end align-center d-flex>
                  <span style="font-weight: bold;">ชื่อแหล่งทุนวิจัย</span>
                </v-flex>
                <v-flex justify-start align-center d-flex>
                  <v-text-field v-model="budgetForm.budgetResource" outlined dense hide-details />
                </v-flex>
              </v-layout>
              <!-- row 2 -->
              <v-layout mt-6 px-6>
                <v-flex xs3 mr-3 justify-end align-center d-flex>
                  <span style="font-weight: bold;">ประเภทแหล่งทุนวิจัย </span>
                </v-flex>
                <v-flex xs9 pt-4 class="field-select">

                  <v-select clearable :menu-props="{ bottom: true, offsetY: true }" background-color="#3E7662" solo flat
                    dense label="ภายใน/ภายนอก" :items="isInsideList" item-text="name" item-value="id"
                    v-model="budgetForm.isInside">
                  </v-select>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn style="font-weight: bold" color="#3E7662" text @click="budgetDialog = false">Close
              </v-btn>
              <v-btn style="font-weight: bold" color="#3E7662" text @click="createBudget">Create</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <loading color="#527281" :active.sync="isLoading" :can-cancel="false" :is-full-page="fullPage"></loading>
  </v-content>
</template>

<script>
import TextfieldForm from "../../components/input/TextfieldForm";
import TextareaForm from "../../components/input/TextareaForm";
import DateInputForm from "../../components/input/DateInputForm";
import TextAutocomplete from "../../components/input/TextAutocomplete";
import Swal from "sweetalert2";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { mapState } from "vuex";
import axios from "axios";
import { domain } from "../../services/Constants";

export default {
  name: "formResearch1",
  components: {
    TextfieldForm,
    TextareaForm,
    DateInputForm,
    TextAutocomplete,
    Loading,
  },
  computed: {
    ...mapState({
      researchID: (state) => state.FormResearch.researchID,
      remainingDate: (state) => state.Common.remainingDate,
    }),
  },
  data: () => ({
    valid: true,
    isLoading: false,
    nowDate: new Date().toISOString().slice(0, 10),
    fullPage: true,
    menu_date_start: false,
    menu_date_end: false,
    modal: false,
    namePDF1: "",
    namePDF2: "",
    namePDF3: "",
    namePDF4: "",
    namePDF5: "",
    namePDF6: "",
    namePDF15: "",
    guarantyFilePath: "",
    researchFilePath: "",
    ro01_1FilePath: "",
    ro01_2FilePath: "",
    crecFilePath: "",
    editPointFilePath: "",
    researcherProfilePath: "",
    submissionType: "0",
    placeHolder: "ผู้วิจัยหลัก",
    form: {
      researchs: {
        thResearchName: "",
        enResearchName: "",
        protocolNumber: "",
        field: "",
        startDate: "",
        endDate: "",
        researchPlace: "",
        researchPlaceEn: "",
        mainResearcherID: "",
        researcherProfile: "",
        ymidtype: "",
        ymidname: "",
        checkboxInterestedGroup: {
          interested1: false,
          interested2: false,
          interested3: false,
          hasInterested3: "",
          interested4: false,
        },
        checkboxSampleGroup: {
          group1: false,
          group2: false,
          group3: false,
          group4: false,
          group5: false,
          group6: false,
          group7: false,
          group8: false,
          group9: false,
          group10: false,
        },
        educationType: "",
        otherEducationType: "",
        otherSampleGroup: "",
        budgetResourceID: "",
        budgetAmount: 0,
        ownerAmount: 0,
        compensationAmount: 0,
        otherAmount: 0,
        guarantyFile: "",
        guarantyNumber: "",
        researchFile: "",
        ro01_1File: "",
        ro01_2File: "",
        crecFile: "",
        editPointFile: "",
        researchFileClean: "",
        researchFileHighlight: "",
        ro01_1FileClean: "",
        ro01_1FileHighlight: "",
        ro01_2FileClean: "",
        ro01_2FileHighlight: "",
        considerResultFile: "",
        crecFile2: "",
        submitReportForConsider: "",
        submitReportForConsiderClean: "",
        submitReportForConsiderHighlight: "",
        researchCode: "",
        isManual: 0,
      },
    },
    researchers: [
      {
        position: "",
        userID: "",
        canManage: false,
        historyFile: ""
      },
    ],
    researchersDelete: [],
    role: [],
    user_list: [],
    researcher_type: [
      {
        id: 1,
        type: "ผู้ร่วมวิจัย",
      },
      {
        id: 2,
        type: "ที่ปรึกษา",
      },
      {
        id: 3,
        type: "ผู้ประสานงาน",
      },
    ],
    dateChange1: "",
    dateChange2: "",
    indexArr: 0,
    budget_list: [],
    isInsideList: [
      {
        id: 0,
        name: "แหล่งทุนภายนอก",
      },
      {
        id: 1,
        name: "แหล่งทุนภายใน",
      },
    ],
    ymidType: [
      {
        id: 0,
        name: "CREC"
      },
      {
        id: 1,
        name: "YMID"
      },
    ],
    isInside: null,
    budgetDialog: false,
    budgetForm: {
      budgetResource: "",
      isInside: "",
    },
    totalAmount: 0,
    researchVersion: 1,
    researchStatus: "",
    isNew: true,
    verifyForm: []
  }),
  async created() {
    this.getRole();
    this.getUserList();
    await this.getFormResearch1();
    await this.getVerifyForm();
    if (this.remainingDate === 0) {
      this.$router.push("/profile");
    }
  },
  methods: {
    updateData(index, val) {
      this.researchers[index].userID = val;
    },
    checkSubmission() {
      if (
        this.researchVersion > 1 ||
        this.researchStatus === "ปรับปรุงแก้ไขเพื่อรับรอง" ||
        this.researchStatus === "ปรับปรุงแก้ไขเพื่อพิจารณาใหม่"
      ) {
        this.submissionType = "1";
        this.isNew = false;
      } else if (
        (this.$route.query.id === "create" || this.researchVersion === 1)
        && this.form.researchs.isManual == 1
      ) {
        this.submissionType = "2";
        this.isNew = true;
      } else if (
        this.$route.query.id === "create" ||
        this.researchVersion === 1
      ) {
        this.submissionType = "0";
        this.isNew = true;
      } else {
        this.submissionType = "";
      }
    },
    updateDataResearchs(name, val) {
      this.form.researchs[name] = val;
      if (
        name === "otherAmount" ||
        name === "ownerAmount" ||
        name === "compensationAmount"
      ) {
        this.calculateTotalAmount();
      }
    },
    updateDate(name, val) {
      if (name === "startDate") {
        this.form.researchs.startDate = val;
        this.dateChange1 = this.changeDate(val);
      }
      if (name === "endDate") {
        this.form.researchs.endDate = val;
        this.dateChange2 = this.changeDate(val);
      }
    },
    changeDate(val) {
      //change date start
      let sub_year = val.substring(0, 4);
      let sub_month = val.substring(5, 7);
      let sub_day = val.substring(8, 10);
      sub_year = parseInt(sub_year) + 543;
      let changeDate = sub_day + "/" + sub_month + "/" + sub_year;
      return changeDate;
    },
    findMatchingProvince(a, b) {
      return a == b;
    },
    changePage() {
      Swal.fire({
        title: "คุณต้องการไปหน้าถัดไปหรือไม่?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3E7662",
        cancelButtonColor: "rgb(189, 189, 189)",
        confirmButtonText: "ใช่",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.value) {
          let errorOccurred = false;
          this.researchers.forEach((data) => {
            if (!errorOccurred && data.position !== null && data.position !== "") {
              if (data.userID == null || data.userID == "") {
                Swal.fire({
                  title: "เกิดข้อผิดพลาด",
                  text: "กรุณาระบุ ผู้ร่วมวิจัย",
                  icon: "warning",
                  confirmButtonColor: "#3E7662",
                });
                errorOccurred = true;
              }
            }
          });
          if (!errorOccurred) {
            const allSmallConditionsPassed = (
              this.form.researchs.protocolNumber != null && this.form.researchs.protocolNumber !== "" &&
              this.form.researchs.ymidname != null && this.form.researchs.ymidname !== "" &&
              this.form.researchs.crecFile != null && this.form.researchs.crecFile !== ""
            );
            if (this.form.researchs.ymidtype == "CREC" || this.form.researchs.ymidtype == "YMID") {
              if (!allSmallConditionsPassed) {
                if (this.form.researchs.protocolNumber == null || this.form.researchs.protocolNumber == "") {
                  Swal.fire({
                    title: "เกิดข้อผิดพลาด",
                    text: "กรุณากรอก Protocol",
                    icon: "warning",
                    confirmButtonColor: "#3E7662",
                  });
                  errorOccurred = true;
                }
                if (this.form.researchs.ymidname == null || this.form.researchs.ymidname == "") {
                  Swal.fire({
                    title: "เกิดข้อผิดพลาด",
                    text: "กรุณากรอกรหัส CREC / YMID",
                    icon: "warning",
                    confirmButtonColor: "#3E7662",
                  });
                  errorOccurred = true;
                }
                if (this.form.researchs.crecFile == null || this.form.researchs.crecFile == "") {
                  Swal.fire({
                    title: "เกิดข้อผิดพลาด",
                    text: "กรุณาแนบไฟล์ CREC / YMID",
                    icon: "warning",
                    confirmButtonColor: "#3E7662",
                  });
                  errorOccurred = true;
                }
              }
            }
            if (!errorOccurred) {
              this.isLoading = true;
              this.form["researchers"] = this.researchers;
              this.form["researchersDelete"] = this.researchersDelete;
              this.form["researchID"] = this.researchID;
              console.log(this.researchID);
              this.form.researchs.isManual = this.submissionType == 2 ? 1 : 0;
              this.$store
                .dispatch("FormResearch/saveResearch1", this.form)
                .then((res) => {
                  this.isLoading = false;
                  if (this.$route.query.id !== "create") {
                    this.$router.push({
                      name: "FormResearch2",
                      query: { id: this.$route.query.id },
                    });
                  } else {
                    this.$router.push({
                      name: "FormResearch2",
                      query: { id: "create" },
                    });
                  }
                })
                .catch((e) => {
                  this.isLoading = false;
                  if (e.response.data.errors) {
                    for (var k in e.response.data.errors) {
                      Swal.fire({
                        title: "เกิดข้อผิดพลาด",
                        text: e.response.data.errors[k][0],
                        icon: "warning",
                        confirmButtonColor: "#3E7662",
                      }).then(() => {
                        this.$vuetify.goTo(`#${k}`);
                      });
                    }
                  }
                });
            }
          }

        }
      });
    },
    getFormResearch1() {
      if (this.researchID !== 0) {
        let data = {
          researchID: this.researchID,
          researchPage: 1,
        };
        this.$store
          .dispatch("FormResearch/getFormResearch1", data)
          .then((res) => {
            this.form.researchs.thResearchName =
              res.thResearchName == null ? "" : res.thResearchName;
            this.form.researchs.enResearchName =
              res.enResearchName == null ? "" : res.enResearchName;
            this.form.researchs.protocolNumber =
              res.protocolNumber == null ? "" : res.protocolNumber;
            this.researchVersion = res.version;
            this.researchStatus = res.status;
            this.form.researchs.researchCode = res.researchCode == null ? "" : res.researchCode;
            this.form.researchs.mainResearcherID =
              res.userID == null ? "" : res.userID;
            this.form.researchs.field = res.field == null ? "" : res.field;
            this.researchers =
              res.researcher_and_adviser == null
                ? ""
                : res.researcher_and_adviser;
            this.form.researchs.startDate =
              res.startDate == null ? "" : res.startDate;
            this.dateChange1 = this.changeDate(res.startDate);
            this.form.researchs.endDate =
              res.endDate == null ? "" : res.endDate;
            this.dateChange2 = this.changeDate(res.endDate);
            this.form.researchs.researchPlace =
              res.researchPlace == null ? "" : res.researchPlace;
            this.form.researchs.researchPlaceEn =
              res.researchPlaceEn == null ? "" : res.researchPlaceEn;
            this.form.researchs.checkboxInterestedGroup =
              res.checkboxInterestedGroup == null
                ? ""
                : res.checkboxInterestedGroup;
            this.form.researchs.checkboxSampleGroup =
              res.checkboxSampleGroup == null ? "" : res.checkboxSampleGroup;
            this.form.researchs.educationType =
              res.educationType == null ? "" : res.educationType;
            this.form.researchs.otherEducationType =
              res.otherEducationType == null ? "" : res.otherEducationType;
            this.form.researchs.otherSampleGroup =
              res.otherSampleGroup == null ? "" : res.otherSampleGroup;
            this.isInside =
              res.budget_resource.isInside == null
                ? ""
                : res.budget_resource.isInside;
            if (this.isInside !== "" && this.isInside !== null) {
              this.getBudgetList();
            }
            this.form.researchs.budgetResourceID =
              res.budgetResourceID == null ? "" : res.budgetResourceID;
            this.form.researchs.budgetAmount =
              res.budgetAmount == null ? "" : res.budgetAmount;
            this.form.researchs.ownerAmount =
              res.ownerAmount == null ? "" : res.ownerAmount;
            this.form.researchs.compensationAmount =
              res.compensationAmount == null ? "" : res.compensationAmount;
            this.form.researchs.otherAmount =
              res.otherAmount == null ? "" : res.otherAmount;
            this.form.researchs.guarantyFile =
              res.guarantyFile == null ? "" : res.guarantyFile;
            this.form.researchs.guarantyNumber =
              res.guarantyNumber == null ? "" : res.guarantyNumber;
            this.form.researchs.researchFile =
              res.researchFile == null ? "" : res.researchFile;
            this.form.researchs.ro01_1File =
              res.ro01_1File == null ? "" : res.ro01_1File;
            this.form.researchs.ro01_2File =
              res.ro01_2File == null ? "" : res.ro01_2File;
            this.form.researchs.crecFile =
              res.crecFile == null ? "" : res.crecFile;
            this.form.researchs.editPointFile =
              res.editPointFile == null ? "" : res.editPointFile;
            this.form.researchs.researchFileClean =
              res.researchFileClean == null ? "" : res.researchFileClean;
            this.form.researchs.researchFileHighlight =
              res.researchFileHighlight == null
                ? ""
                : res.researchFileHighlight;
            this.form.researchs.ro01_1FileClean =
              res.ro01_1FileClean == null ? "" : res.ro01_1FileClean;
            this.form.researchs.ro01_1FileHighlight =
              res.ro01_1FileHighlight == null ? "" : res.ro01_1FileHighlight;
            this.form.researchs.ro01_2FileClean =
              res.ro01_2FileClean == null ? "" : res.ro01_2FileClean;
            this.form.researchs.ro01_2FileHighlight =
              res.ro01_2FileHighlight == null ? "" : res.ro01_2FileHighlight;
            this.form.researchs.considerResultFile =
              res.considerResultFile == null ? "" : res.considerResultFile;
            this.form.researchs.crecFile2 =
              res.crecFile2 == null ? "" : res.crecFile2;
            this.form.researchs.submitReportForConsider =
              res.submitReportForConsider == null ? "" : res.submitReportForConsider;
            this.form.researchs.submitReportForConsiderClean =
              res.submitReportForConsiderClean == null ? "" : res.submitReportForConsiderClean;
            this.form.researchs.submitReportForConsiderHighlight =
              res.submitReportForConsiderHighlight == null ? "" : res.submitReportForConsiderHighlight;
            this.namePDF1 = res.guarantyFile == null ? "" : res.guarantyFile;
            this.namePDF2 = res.researchFile == null ? "" : res.researchFile;
            this.namePDF3 = res.ro01_1File == null ? "" : res.ro01_1File;
            this.namePDF4 = res.ro01_2File == null ? "" : res.ro01_2File;
            this.namePDF5 = res.crecFile == null ? "" : res.crecFile;
            this.namePDF6 = res.editPointFile == null ? "" : res.editPointFile;
            this.namePDF7 =
              res.researchFileClean == null ? "" : res.researchFileClean;
            this.namePDF8 =
              res.researchFileHighlight == null
                ? ""
                : res.researchFileHighlight;
            this.namePDF9 =
              res.ro01_1FileClean == null ? "" : res.ro01_1FileClean;
            this.namePDF10 =
              res.ro01_1FileHighlight == null ? "" : res.ro01_1FileHighlight;
            this.namePDF11 =
              res.ro01_2FileClean == null ? "" : res.ro01_2FileClean;
            this.namePDF12 =
              res.ro01_2FileHighlight == null ? "" : res.ro01_2FileHighlight;
            this.namePDF13 =
              res.considerResultFile == null ? "" : res.considerResultFile;
            this.namePDF14 = res.crecFile2 == null ? "" : res.crecFile2;
            this.namePDF15 = res.submitReportForConsider == null ? "" : res.submitReportForConsider;
            this.namePDF16 = res.submitReportForConsiderClean == null ? "" : res.submitReportForConsiderClean;
            this.namePDF17 = res.submitReportForConsiderHighlight == null ? "" : res.submitReportForConsiderHighlight;
            this.guarantyFilePath = res.guarantyFilePath;
            this.researchFilePath = res.researchFilePath;
            this.ro01_1FilePath = res.ro01_1FilePath;
            this.ro01_2FilePath = res.ro01_2FilePath;
            this.crecFilePath = res.crecFilePath;
            this.editPointFilePath = res.editPointFilePath;
            this.researchFileCleanPath = res.researchFileCleanPath;
            this.researchFileHighlightPath = res.researchFileHighlightPath;
            this.ro01_1FileCleanPath = res.ro01_1FileCleanPath;
            this.ro01_1FileHighlightPath = res.ro01_1FileHighlightPath;
            this.ro01_2FileCleanPath = res.ro01_2FileCleanPath;
            this.ro01_2FileHighlightPath = res.ro01_2FileHighlightPath;
            this.considerResultFilePath = res.considerResultFilePath;
            this.crecFile2Path = res.crecFile2Path;
            this.submitReportForConsiderPath = res.submitReportForConsiderPath;
            this.submitReportForConsiderCleanPath = res.submitReportForConsiderCleanPath;
            this.submitReportForConsiderHighlightPath = res.submitReportForConsiderHighlightPath;
            this.form.researchs.isManual = res.isManual
            this.form.researchs.researcherProfile = res.researcherProfile
            this.form.researchs.ymidname = res.ymidname
            this.form.researchs.ymidtype = res.ymidtype
            this.researcherProfilePath = res.researcherProfilePath
            this.calculateTotalAmount();
            this.checkSubmission();
            console.log('res', res);
          });
      }
    },
    add(index) {
      this.researchers.push({ position: "", userID: "", canManage: false, historyFile: "" });
      this.indexArr = parseInt(this.indexArr) + index;
    },
    remove(index) {
      var objs = {};
      objs = this.researchers.splice(index, 1);
      objs.forEach((obj) => {
        this.researchersDelete.push(obj);
      });
      this.indexArr = parseInt(this.indexArr) - 1;
    },
    getRole() {
      this.isLoading = true;
      this.$store.dispatch("Common/getRole").then((res) => {
        res.forEach((data) => {
          this.role.push({
            role_id: data.id,
            role_name: data.role,
          });
        });
        this.isLoading = false;
      });
    },
    getUserList() {
      this.isLoading = true;
      this.$store.dispatch("Common/getUserList").then((res) => {
        res.all_researcher_data.forEach((data) => {
          this.user_list.push({
            user_id: data.id,
            user_name: data.thName + ' (' + data.enName + ')',
          });
        });
        this.isLoading = false;
      });
    },
    // upload file
    uploadFile(name) {
      document.getElementById(name).click();
    },
    openPDF(val, isDynamic = 0) {
      console.log('val', val);
      if (isDynamic)
        window.open(val)
      else
        window.open(this[val])
    },
    uploadFileChange(fileName, e, index = 0) {
      var formData = new FormData();
      formData.append(fileName, e);
      axios.post(domain + "/api/upload-file-pre", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          if (response.data.fileName !== undefined) {
            if (fileName === 'historyFile')
              this.researchers[index][fileName] = response.data.fileName;
            else
              this.form.researchs[fileName] = response.data.fileName;
          }
          else {
            if (fileName === 'historyFile')
              this.researchers[index][fileName] = '';
            else
              this.form.researchs[fileName] = '';
          }
          console.log(this.researchers, this.form.researchs)
        })
        .catch(function (error) { });
    },
    clearTextField(name) {
      switch (name) {
        case "otherSampleGroup":
          this.form.researchs.otherSampleGroup = "";
          break;
        case "otherEducationType":
          this.form.researchs.otherEducationType = "";
          break;
        case "ownerAmount":
          this.form.researchs.checkboxInterestedGroup.interested4 = false;
          this.form.researchs.ownerAmount = 0;
          this.calculateTotalAmount();
          break;
        case "compensationAmount":
          this.form.researchs.checkboxInterestedGroup.interested4 = false;
          this.form.researchs.compensationAmount = 0;
          this.calculateTotalAmount();
          break;
        case "otherAmount":
          this.form.researchs.checkboxInterestedGroup.interested4 = false;
          this.form.researchs.checkboxInterestedGroup.hasInterested3 = "";
          this.form.researchs.otherAmount = 0;
          this.calculateTotalAmount();
          break;
        case "dontHaveAmount":
          if (this.form.researchs.checkboxInterestedGroup.interested4) {
            this.form.researchs.checkboxInterestedGroup.interested1 = false;
            this.form.researchs.checkboxInterestedGroup.interested2 = false;
            this.form.researchs.checkboxInterestedGroup.interested3 = false;
            this.form.researchs.checkboxInterestedGroup.hasInterested3 = "";
            this.form.researchs.ownerAmount = 0;
            this.form.researchs.compensationAmount = 0;
            this.form.researchs.otherAmount = 0;
            this.calculateTotalAmount();
          }
      }
    },
    calculateTotalAmount() {
      this.totalAmount =
        parseInt(this.form.researchs.ownerAmount) +
        parseInt(this.form.researchs.compensationAmount) +
        parseInt(this.form.researchs.otherAmount);
    },
    getBudgetList() {
      this.budget_list = [];
      this.isLoading = true;
      this.$store
        .dispatch("Common/getBudgetList", this.isInside)
        .then((res) => {
          res.forEach((data) => {
            this.budget_list.push({
              budget_id: data.id,
              budget_name: data.budgetResource,
            });
          });
          this.isLoading = false;
        })
        .catch((e) => {
          this.isLoading = false;
        });
    },
    createBudgetModal() {
      this.budgetDialog = true;
    },
    cancelFile(name, pdfName) {
      this.form.researchs[name] = '';
      this[pdfName] = '';
    },
    createBudget() {
      this.isLoading = true;
      let data = {
        budgetResource: this.budgetForm.budgetResource,
        isInside: this.budgetForm.isInside,
      };
      this.$store
        .dispatch("Budget/createBudget", data)
        .then((res) => {
          this.isLoading = false;
          Swal.fire({
            title: "เพิ่มแหล่งทุนวิจัยเสร็จสิ้น",
            icon: "success",
            position: "center",
            showConfirmButton: false,
            background: "#D8D8D8",
            timer: 3000,
          }).then(() => {
            if (this.isInside != null) {
              this.getBudgetList();
            }
          });
        })
        .catch((e) => {
          this.isLoading = false;
          Swal.fire({
            title: "เกิดข้อผิดพลาด",
            text: "บันทึกไม่สำเร็จ",
            icon: "warning",
          });
        });
    },
    selectSendType() {
      if (!this.submissionType == 2) {
        this.form.researchs.researchCode = ""
      }
    },
    getVerifyForm() {
      this.verifyForm = [];
      if (this.researchID !== 0) {
        this.isLoading = true;
        this.$store
          .dispatch("FormResearch/getResearchVerify", this.researchID)
          .then((res) => {
            this.verifyForm = res
            this.isLoading = false;
          })
          .catch((e) => {
            this.isLoading = false;
          });
      }
    },
  },
};
</script>
<style lang="scss"></style>

<style>
.link__pdf:hover {
  cursor: pointer;
  text-decoration: underline;
  font-weight: bold;
}

.v-input--selection-controls .v-input__control {
  width: 100%;
}
</style>
